import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

const pageQuery = graphql(`
  query DemoSearchPageQuery($prompt: String!) {
    searchEmails(prompt: $prompt) {
      id
      subject
      body
      receivedAt
      nps
      company {
        id
        name
      }
    }
  }
`);

import { useSuspenseQuery } from "@apollo/client";
import { FaMagnifyingGlass } from "react-icons/fa6";
import ReactMarkdown from "react-markdown";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import { PageContent, PageHeader } from "../../../components/Page";
import { Button, buttonVariants } from "../../../components/ui/button";
import { graphql } from "../../../gql";
import { formatDateFromString } from "../../../utils/date.utils";
export const SearchResultPage = () => {
  const q = useSearchParam("q");

  const {
    data: { searchEmails },
  } = useSuspenseQuery(pageQuery, { variables: { prompt: q! } });

  const navigate = useNavigate();
  return (
    <>
      <PageHeader title="Dashboard">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(
              `/dashboards/search?q=${
                (e.currentTarget.querySelector("#search") as any).value
              }`,
              { replace: true }
            );
          }}
          className="border border-zinc-400 rounded-full overflow-hidden p-0"
        >
          <input
            id="search"
            type="search"
            defaultValue={q ?? ""}
            className="p-1 px-4 my-0 outline-none"
            // onChange={(e) => setSearch(e.target.value)}
          />
          <Button size="icon" variant="ghost" type="submit">
            <FaMagnifyingGlass />
          </Button>
        </form>
      </PageHeader>
      <PageContent>
        <Card>
          <CardHeader className="flex items-center justify-between">
            <CardTitle
              title="Matching Emails"
              // icon={<FaExclamationCircle className="text-red-600" />}
            />
          </CardHeader>
          <CardBody>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Company</TableHead>
                  <TableHead>NPS</TableHead>
                  <TableHead>Received At</TableHead>
                  <TableHead>Email</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {searchEmails.map((email) => (
                  <TableRow>
                    <TableCell className="align-top">
                      <Link
                        to={`/dashboards/${email.company.id}`}
                        className={buttonVariants({ variant: "link" })}
                      >
                        {email.company.name}
                      </Link>
                    </TableCell>
                    <TableCell className="align-top">{email.nps}</TableCell>
                    <TableCell className="align-top">
                      {formatDateFromString(email.receivedAt)}
                    </TableCell>
                    <TableCell className="">
                      <div>
                        <strong>Subject:</strong> {email.subject}
                      </div>
                      <ReactMarkdown className="prose prose-sm">
                        {email.body}
                      </ReactMarkdown>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
};
