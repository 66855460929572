import { CgWebsite } from "react-icons/cg";
import { FaFolderOpen } from "react-icons/fa6";
import {
  SiGoogledrive,
  SiMicrosoftonedrive,
  SiMicrosoftsharepoint,
} from "react-icons/si";
import { SpaceSource } from "../../../gql/graphql";

export const SpaceIcon: React.FC<{
  source: SpaceSource;
  className?: string;
}> = ({ source, className }) => {
  switch (source) {
    case SpaceSource.GoogleDrive:
      return <SiGoogledrive className={className} />;

    case SpaceSource.MsOneDrive:
      return <SiMicrosoftonedrive className={className} />;

    case SpaceSource.MsSharepoint:
      return <SiMicrosoftsharepoint className={className} />;
    case SpaceSource.Url:
      return <CgWebsite className={className} />;
    default:
      return <FaFolderOpen className={className} />;
  }
};
