import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultValues, FormProvider, useForm } from "react-hook-form";
import {
  ZodObject,
  ZodRawShape,
  ZodRecord,
  ZodString,
  ZodTypeAny,
  z,
} from "zod";

type FormSchema = ZodObject<ZodRawShape> | ZodRecord<ZodString, ZodTypeAny>;

interface Props<TSchema extends FormSchema> {
  schema: TSchema;
  defaultValue?: DefaultValues<z.infer<TSchema>>;
  onSubmit(value: z.infer<TSchema>): void | Promise<unknown>;
  children?: React.ReactNode;
  className?: string;
  id?: string;
}
export function Reform<TSchema extends FormSchema>({
  defaultValue,
  onSubmit,
  children,
  schema,
  className,
  id,
}: Props<TSchema>) {
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: defaultValue,
    reValidateMode: "onBlur",
  });

  return (
    <form
      onSubmit={methods.handleSubmit((data, event) => {
        event?.preventDefault();
        void onSubmit(data);
      })}
      className={className}
      id={id}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </form>
  );
}
