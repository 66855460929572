import { PropsWithChildren } from "react";
import { MinimalSpaceFileFragment } from "../../../gql/graphql";
import { cn } from "../../../lib/utils";
import { SpaceFileIcon, mimetypeColor } from "./SpaceFileIcon";

export const SpaceFileLink: React.FC<
  PropsWithChildren<{ file: MinimalSpaceFileFragment; className?: string }>
> = ({ file, children, className }) => {
  return (
    <a href={file.url} target={file.id} className={className}>
      {children}
    </a>
  );
};

export const SpaceFileStylizedLink: React.FC<
  PropsWithChildren<{ file: MinimalSpaceFileFragment; className?: string }>
> = ({ file, children, className }) => {
  return (
    <SpaceFileLink
      file={file}
      className={cn(
        mimetypeColor(file.originalMimeType),
        "flex gap-1  items-center text-sm",
        className
      )}
    >
      <SpaceFileIcon file={file} />
      {children}
    </SpaceFileLink>
  );
};
