import { useSuspenseQuery } from "@apollo/client";
import { Card, CardBody } from "../../../components/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { graphql } from "../../../gql";
import { formatDateTimeFromString } from "../../../utils/date.utils";

const searchRecordsQuery = graphql(`
  query FindSearchRecordsForOrganization {
    searchRecordsForOrganization {
      id
      createdAt
      question
      user {
        id
        name
        email
      }
    }
  }
`);
export const SearchDashboardPage: React.FC = () => {
  const { data } = useSuspenseQuery(searchRecordsQuery);
  return (
    <Card>
      <CardBody>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Asked At</TableHead>
              <TableHead>Question</TableHead>
              <TableHead>User</TableHead>
              {/* <TableHead></TableHead> */}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.searchRecordsForOrganization.map((record) => (
              <TableRow>
                <TableCell>
                  {formatDateTimeFromString(record.createdAt)}
                </TableCell>
                <TableCell>{record.question}</TableCell>
                <TableCell>{record.user.name ?? record.user.email}</TableCell>
                {/* <TableCell>
                  <Link
                    to={`./${record.id}`}
                    className={buttonVariants({ variant: "link" })}
                  >
                    Details
                  </Link>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  );
};
