import { useMutation } from "@apollo/client";
import { FaExclamationCircle } from "react-icons/fa";
import {
  FaArrowDown,
  FaArrowUp,
  FaCircleCheck,
  FaRobot,
} from "react-icons/fa6";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { toast } from "sonner";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Spinner } from "../../../components/Spinner";
import { Button } from "../../../components/ui/button";
import { graphql } from "../../../gql";
import {
  AnsweredSearchRecordFragment,
  SearchRecordSentiment,
  SearchRecordSentimentFragment,
  SearchRecordStatus,
} from "../../../gql/graphql";

export const SearchRecordAnswerCard: React.FC<{
  searchRecord: AnsweredSearchRecordFragment;
  className?: string;
}> = ({ searchRecord, className }) => {
  let icon: React.ReactNode;
  if (searchRecord.status === SearchRecordStatus.AnswerGenerated) {
    icon = <SentimentVote record={searchRecord} />;
  } else if (searchRecord.status === SearchRecordStatus.Error) {
    icon = <FaExclamationCircle className="text-amber-600" />;
  } else {
    icon = <Spinner />;
  }

  return (
    <Card className={className}>
      <CardHeader sticky className="flex justify-between">
        <CardTitle icon={<FaRobot />} title="AI Answer" />

        {icon}
      </CardHeader>
      <CardBody>
        <Markdown remarkPlugins={[remarkGfm]} className="prose prose-zinc">
          {searchRecord.aiAnswer}
        </Markdown>
      </CardBody>
    </Card>
  );
};

const updateSentimentMutation = graphql(`
  mutation UpdateSearchRecordSentiment(
    $id: ID!
    $sentiment: SearchRecordSentiment!
  ) {
    updateSearchRecord(input: { id: $id, sentiment: $sentiment }) {
      id
      sentiment
    }
  }
`);

const SentimentVote: React.FC<{ record: SearchRecordSentimentFragment }> = ({
  record,
}) => {
  const [updateSentiment] = useMutation(updateSentimentMutation, {
    onCompleted: () => toast.success("Success"),
    onError: () => toast.error("We're sorry, an error occurred"),
  });

  if (record.sentiment) {
    return <FaCircleCheck className="text-primary" />;
  }

  return (
    <div className=" flex gap-2">
      <Button
        size="icon"
        variant="outline"
        className="h-8 w-8 shadow-none"
        onClick={() =>
          updateSentiment({
            variables: {
              id: record.id,
              sentiment: SearchRecordSentiment.Positive,
            },
          })
        }
      >
        <FaArrowUp />
      </Button>
      <Button
        size="icon"
        className="h-8 w-8 shadow-none"
        variant="default"
        onClick={() =>
          updateSentiment({
            variables: {
              id: record.id,
              sentiment: SearchRecordSentiment.Negative,
            },
          })
        }
      >
        <FaArrowDown />
      </Button>
    </div>
  );
};
