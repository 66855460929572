import { useLazyQuery } from "@apollo/client";
import { z } from "zod";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../components/Card";
import { Reform } from "../../../components/Reform";
import { Bound } from "../../../components/forms/boundComponents";
import { buttonVariants } from "../../../components/ui/button";
import { graphql } from "../../../gql";
import { SearchAdminSearchQuery } from "../../../gql/graphql";
import { SpaceFileStylizedLink } from "../../spaces/components/SpaceFileLink";

const getSearchRecordsQuery = graphql(`
  query SearchAdminSearch($prompt: String!) {
    search(query: { prompt: $prompt }) {
      id
      chunks {
        id
        markdown
        spaceFile {
          ...MinimalSpaceFile
        }
        cells {
          id
          score
          text
        }
      }
    }
  }
`);

const formSchema = z.object({
  prompt: z.string(),
});

export const SearchRecordAdmin: React.FC = () => {
  const [getQuery, { loading, data }] = useLazyQuery(getSearchRecordsQuery, {
    fetchPolicy: "network-only",
  });

  const search = data?.search;

  return (
    <div className="flex flex-col gap-4">
      <Reform
        schema={formSchema}
        onSubmit={({ prompt }) => getQuery({ variables: { prompt } })}
      >
        <Card>
          <CardBody>
            <Bound.Label name="prompt">Prompt</Bound.Label>
            <Bound.Textarea name="prompt" required />
          </CardBody>
          <CardFooter>
            <Bound.Button disabled={loading}>Submit</Bound.Button>
          </CardFooter>
        </Card>
      </Reform>

      {search ? (
        search.chunks.map((chunk) => (
          <ChunkRenderer key={chunk.id} chunk={chunk} />
        ))
      ) : (
        <p>Make a search</p>
      )}
    </div>
  );
};

const ChunkRenderer: React.FC<{
  chunk: SearchAdminSearchQuery["search"]["chunks"][number];
}> = ({ chunk }) => {
  return (
    <Card>
      <CardHeader className="flex justify-between">
        <SpaceFileStylizedLink file={chunk.spaceFile}>
          {chunk.spaceFile.name}
        </SpaceFileStylizedLink>

        <a
          href={`/space-files/${chunk.spaceFile.id}/download`}
          target="_blank"
          className={buttonVariants({ variant: "link" })}
        >
          Download PDF
        </a>
      </CardHeader>
      <h2 className="pb-2 pt-3 ">Cells</h2>
      <ul className="flex flex-wrap gap-1">
        {chunk.cells.map((cell) => (
          <li
            key={cell.id}
            className="rounded-sm p-1 border border-zinc-400 flex flex-col"
          >
            <span className="text-xs text-zinc-600">{cell.score}</span>
            {cell.text}
          </li>
        ))}
      </ul>

      <h2 className="pb-2 pt-3 ">Markdown</h2>
      <div className=" whitespace-pre-wrap border border-zinc-700">
        {chunk.markdown}
      </div>
    </Card>
  );
};
