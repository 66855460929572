/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query Me {\n    me {\n      id\n      name\n      createdAt\n      email\n      icHash\n    }\n  }\n": types.MeDocument,
    "\n  query UserIsReady {\n    userIsReady\n  }\n": types.UserIsReadyDocument,
    "\n  query MyOrganization {\n    myOrganization {\n      id\n      name\n    }\n  }\n": types.MyOrganizationDocument,
    "\n  mutation ReindexOrg {\n    reindexMyOrganization\n  }\n": types.ReindexOrgDocument,
    "\n  query GetSearchContentCells($prompt: String!) {\n    searchContentCells(prompt: $prompt) {\n      contentCells {\n        id\n        text\n        score\n      }\n      documents {\n        id\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n": types.GetSearchContentCellsDocument,
    "\n  query GetSearchContentBlocks($prompt: String!) {\n    searchContentBlocks(prompt: $prompt) {\n      contentBlocks {\n        ... on HeaderContentBlock {\n          id\n          text\n          parentId\n        }\n        ... on ListContentBlock {\n          id\n          parentId\n        }\n        ... on ListItemContentBlock {\n          id\n          text\n          parentId\n        }\n        ... on ParagraphContentBlock {\n          id\n          text\n          parentId\n        }\n      }\n      documents {\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n": types.GetSearchContentBlocksDocument,
    "\n  query GetSearchDocuments($prompt: String!) {\n    searchDocuments(prompt: $prompt) {\n      documents {\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n": types.GetSearchDocumentsDocument,
    "\n  query GetSearchQuery($prompt: String!) {\n    searchQuery(prompt: $prompt) {\n      namedEntities\n      phrases\n    }\n  }\n": types.GetSearchQueryDocument,
    "\n  query SearchAdminSearch($prompt: String!) {\n    search(query: { prompt: $prompt }) {\n      id\n      chunks {\n        id\n        markdown\n        spaceFile {\n          ...MinimalSpaceFile\n        }\n        cells {\n          id\n          score\n          text\n        }\n      }\n    }\n  }\n": types.SearchAdminSearchDocument,
    "\n  query AtRiskPage {\n    atRiskCompanies {\n      id\n      name\n      nps\n      alerts {\n        id\n        name\n      }\n    }\n  }\n": types.AtRiskPageDocument,
    "\n  mutation CreateAlert($input: CreateAlertInput!) {\n    createAlert(input: $input)\n  }\n": types.CreateAlertDocument,
    "\n  mutation DeleteAlert($id: ID!) {\n    deleteAlert(id: $id) {\n      id\n      name\n      alerts {\n        id\n        name\n      }\n    }\n  }\n": types.DeleteAlertDocument,
    "\n  query ChunDetailPage($id: ID!) {\n    npsTrailingYearAvg(where: { companyId: $id }) {\n      year\n      month\n      avg\n    }\n    company(id: $id) {\n      id\n      name\n      alerts {\n        id\n        name\n        email {\n          id\n          subject\n          body\n          receivedAt\n          nps\n        }\n      }\n    }\n  }\n": types.ChunDetailPageDocument,
    "\n  query ChurnDashboardQuery {\n    npsTrailingYearCounts {\n      year\n      month\n      low\n      med\n      high\n    }\n    problematicReps {\n      id\n      name\n      nps\n    }\n\n    atRiskCompanies {\n      id\n      name\n      nps\n    }\n  }\n": types.ChurnDashboardQueryDocument,
    "\n  query RepDetailPage($id: ID!) {\n    person(id: $id) {\n      id\n      name\n      nps\n      emails {\n        id\n        subject\n        body\n        receivedAt\n        nps\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.RepDetailPageDocument,
    "\n  query DemoSearchPageQuery($prompt: String!) {\n    searchEmails(prompt: $prompt) {\n      id\n      subject\n      body\n      receivedAt\n      nps\n      company {\n        id\n        name\n      }\n    }\n  }\n": types.DemoSearchPageQueryDocument,
    "\n  mutation UpdateManyConnectionDrives($input: [UpdateConnectionDriveInput!]!) {\n    updateManyConnectionDrives(input: $input) {\n      id\n      status\n    }\n  }\n": types.UpdateManyConnectionDrivesDocument,
    "\n  query FindConnection($id: ID!) {\n    connection(id: $id) {\n      ...MinimalConnection\n      drives {\n        id\n        name\n        url\n        status\n      }\n    }\n  }\n": types.FindConnectionDocument,
    "\n  mutation CreateConnection($integrationId: IntegrationKey!) {\n    createConnection(integrationId: $integrationId) {\n      id\n      integrationKey\n      isAuthorized\n      integration {\n        id\n        connection {\n          id\n        }\n      }\n    }\n  }\n": types.CreateConnectionDocument,
    "\n  mutation DeleteConnection($id: ID!) {\n    deleteConnection(id: $id) {\n      id\n      integration {\n        id\n        name\n        connection {\n          id\n        }\n      }\n    }\n  }\n": types.DeleteConnectionDocument,
    "\n  mutation ReimportConnectionDrives($id: ID!) {\n    reimportConnectionDrives(id: $id) {\n      id\n      drives {\n        id\n        name\n        url\n        status\n      }\n    }\n  }\n": types.ReimportConnectionDrivesDocument,
    "fragment Integration on Integration {\n  id\n  name\n  provider\n  requiredScope\n  connection {\n    ...MinimalConnection\n  }\n}": types.IntegrationFragmentDoc,
    "fragment MinimalConnection on Connection {\n  id\n  isAuthorized\n}": types.MinimalConnectionFragmentDoc,
    "\n  query FindIntegrations {\n    integrations {\n      ...Integration\n    }\n  }\n": types.FindIntegrationsDocument,
    "\n  mutation AnswerCoworkerQuestion($id: ID!, $answer: String!) {\n    answerCoworkerQuestion(id: $id, answer: $answer) {\n      ...CoworkerQuestion\n    }\n  }\n": types.AnswerCoworkerQuestionDocument,
    "\n  mutation DeleteCoworkerQuestion($id: ID!) {\n    deleteCoworkerQuestion(id: $id)\n  }\n": types.DeleteCoworkerQuestionDocument,
    "fragment CoworkerQuestion on CoworkerQuestion {\n  id\n  question\n  answer\n  askedBy {\n    id\n    email\n    name\n  }\n  answeredBy {\n    id\n    email\n    name\n  }\n  answeredAt\n}": types.CoworkerQuestionFragmentDoc,
    "\n  query CoworkerQuestions {\n    answered: coworkerQuestions(where: { isAnswered: true }) {\n      ...CoworkerQuestion\n    }\n    unanswered: coworkerQuestions(where: { isAnswered: false }) {\n      ...CoworkerQuestion\n    }\n  }\n": types.CoworkerQuestionsDocument,
    "\n  query FindSearchRecordsForOrganization {\n    searchRecordsForOrganization {\n      id\n      createdAt\n      question\n      user {\n        id\n        name\n        email\n      }\n    }\n  }\n": types.FindSearchRecordsForOrganizationDocument,
    "\n  query SearchRecordDetails($id: ID!) {\n    searchRecord(id: $id) {\n      id\n      createdAt\n      question\n      aiAnswer\n      user {\n        id\n        name\n        email\n      }\n      chunks {\n        id\n        markdown\n        cells {\n          id\n          text\n        }\n        spaceFile {\n          ...MinimalSpaceFile\n        }\n      }\n    }\n  }\n": types.SearchRecordDetailsDocument,
    "\n  query OrganizationUsers {\n    usersForMyOrganization {\n      id\n      createdAt\n      lastActiveAt\n      email\n      name\n    }\n  }\n": types.OrganizationUsersDocument,
    "\n  query Search($query: SearchQueryInput!) {\n    search(query: $query) {\n      id\n    }\n  }\n": types.SearchDocument,
    "\n  query SpacesForSearchBar {\n    spaces {\n      id\n      name\n    }\n  }\n": types.SpacesForSearchBarDocument,
    "\n  mutation UpdateSearchRecordSentiment(\n    $id: ID!\n    $sentiment: SearchRecordSentiment!\n  ) {\n    updateSearchRecord(input: { id: $id, sentiment: $sentiment }) {\n      id\n      sentiment\n    }\n  }\n": types.UpdateSearchRecordSentimentDocument,
    "fragment AnsweredSearchRecord on SearchRecord {\n  id\n  aiAnswer\n  status\n}": types.AnsweredSearchRecordFragmentDoc,
    "fragment SearchBarRecord on SearchRecord {\n  id\n  question\n}": types.SearchBarRecordFragmentDoc,
    "fragment SearchRecordChunk on SearchRecordChunk {\n  id\n  status\n  quotes\n  spaceFile {\n    ...MinimalSpaceFile\n  }\n}": types.SearchRecordChunkFragmentDoc,
    "fragment SearchRecordSentiment on SearchRecord {\n  id\n  sentiment\n}": types.SearchRecordSentimentFragmentDoc,
    "\n  query UnansweredQuestions {\n    unansweredQuestions: coworkerQuestions(where: { isAnswered: false }) {\n      ...CoworkerQuestion\n    }\n  }\n": types.UnansweredQuestionsDocument,
    "\n  query GetSearchRecord($id: ID!) {\n    searchRecord(id: $id) {\n      ...AnsweredSearchRecord\n      ...SearchBarRecord\n      ...SearchRecordSentiment\n      id\n      userId\n      question\n      chunks {\n        ...SearchRecordChunk\n      }\n      relatedQuestions {\n        ...CoworkerQuestion\n      }\n    }\n  }\n": types.GetSearchRecordDocument,
    "\n  mutation DeleteSpaceFile($id: ID!) {\n    deleteSpaceFile(id: $id)\n  }\n": types.DeleteSpaceFileDocument,
    "\n  mutation ReprocessSPaceFile($id: ID!) {\n    reprocessSpaceFile(id: $id) {\n      id\n      status\n    }\n  }\n": types.ReprocessSPaceFileDocument,
    "\n  mutation ReingestSpaceFile($id: ID!) {\n    reingestSpaceFile(id: $id) {\n      id\n      status\n    }\n  }\n": types.ReingestSpaceFileDocument,
    "fragment MinimalSpaceFile on SpaceFile {\n  id\n  path\n  originalMimeType\n  name\n  source\n  sourceId\n  status\n  url\n}": types.MinimalSpaceFileFragmentDoc,
    "\n  mutation SaveNote($input: SaveNoteInput!) {\n    saveNote(input: $input) {\n      id\n    }\n  }\n": types.SaveNoteDocument,
    "\n  mutation UpsertManualSpace($name: String!) {\n    upsertManualSpace(input: { name: $name }) {\n      id\n    }\n  }\n": types.UpsertManualSpaceDocument,
    "\n  mutation UpsertUrlSpace($input: UpsertUrlSpaceInput!) {\n    upsertUrlSpace(input: $input) {\n      id\n    }\n  }\n": types.UpsertUrlSpaceDocument,
    "\n  query SpaceDetails($id: ID!) {\n    space(id: $id) {\n      id\n      name\n      sourceId\n      source\n      files {\n        ...MinimalSpaceFile\n      }\n    }\n  }\n": types.SpaceDetailsDocument,
    "\n  query MySpaces {\n    spaces {\n      id\n      name\n      source\n    }\n  }\n": types.MySpacesDocument,
    "\n  mutation DeleteSpace($id: ID!) {\n    deleteSpace(id: $id)\n  }\n": types.DeleteSpaceDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Me {\n    me {\n      id\n      name\n      createdAt\n      email\n      icHash\n    }\n  }\n"): (typeof documents)["\n  query Me {\n    me {\n      id\n      name\n      createdAt\n      email\n      icHash\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserIsReady {\n    userIsReady\n  }\n"): (typeof documents)["\n  query UserIsReady {\n    userIsReady\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyOrganization {\n    myOrganization {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query MyOrganization {\n    myOrganization {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReindexOrg {\n    reindexMyOrganization\n  }\n"): (typeof documents)["\n  mutation ReindexOrg {\n    reindexMyOrganization\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSearchContentCells($prompt: String!) {\n    searchContentCells(prompt: $prompt) {\n      contentCells {\n        id\n        text\n        score\n      }\n      documents {\n        id\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSearchContentCells($prompt: String!) {\n    searchContentCells(prompt: $prompt) {\n      contentCells {\n        id\n        text\n        score\n      }\n      documents {\n        id\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSearchContentBlocks($prompt: String!) {\n    searchContentBlocks(prompt: $prompt) {\n      contentBlocks {\n        ... on HeaderContentBlock {\n          id\n          text\n          parentId\n        }\n        ... on ListContentBlock {\n          id\n          parentId\n        }\n        ... on ListItemContentBlock {\n          id\n          text\n          parentId\n        }\n        ... on ParagraphContentBlock {\n          id\n          text\n          parentId\n        }\n      }\n      documents {\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSearchContentBlocks($prompt: String!) {\n    searchContentBlocks(prompt: $prompt) {\n      contentBlocks {\n        ... on HeaderContentBlock {\n          id\n          text\n          parentId\n        }\n        ... on ListContentBlock {\n          id\n          parentId\n        }\n        ... on ListItemContentBlock {\n          id\n          text\n          parentId\n        }\n        ... on ParagraphContentBlock {\n          id\n          text\n          parentId\n        }\n      }\n      documents {\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSearchDocuments($prompt: String!) {\n    searchDocuments(prompt: $prompt) {\n      documents {\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSearchDocuments($prompt: String!) {\n    searchDocuments(prompt: $prompt) {\n      documents {\n        spaceFile {\n          id\n          path\n        }\n      }\n      query {\n        namedEntities\n        phrases\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSearchQuery($prompt: String!) {\n    searchQuery(prompt: $prompt) {\n      namedEntities\n      phrases\n    }\n  }\n"): (typeof documents)["\n  query GetSearchQuery($prompt: String!) {\n    searchQuery(prompt: $prompt) {\n      namedEntities\n      phrases\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchAdminSearch($prompt: String!) {\n    search(query: { prompt: $prompt }) {\n      id\n      chunks {\n        id\n        markdown\n        spaceFile {\n          ...MinimalSpaceFile\n        }\n        cells {\n          id\n          score\n          text\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchAdminSearch($prompt: String!) {\n    search(query: { prompt: $prompt }) {\n      id\n      chunks {\n        id\n        markdown\n        spaceFile {\n          ...MinimalSpaceFile\n        }\n        cells {\n          id\n          score\n          text\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AtRiskPage {\n    atRiskCompanies {\n      id\n      name\n      nps\n      alerts {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AtRiskPage {\n    atRiskCompanies {\n      id\n      name\n      nps\n      alerts {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAlert($input: CreateAlertInput!) {\n    createAlert(input: $input)\n  }\n"): (typeof documents)["\n  mutation CreateAlert($input: CreateAlertInput!) {\n    createAlert(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteAlert($id: ID!) {\n    deleteAlert(id: $id) {\n      id\n      name\n      alerts {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteAlert($id: ID!) {\n    deleteAlert(id: $id) {\n      id\n      name\n      alerts {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ChunDetailPage($id: ID!) {\n    npsTrailingYearAvg(where: { companyId: $id }) {\n      year\n      month\n      avg\n    }\n    company(id: $id) {\n      id\n      name\n      alerts {\n        id\n        name\n        email {\n          id\n          subject\n          body\n          receivedAt\n          nps\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ChunDetailPage($id: ID!) {\n    npsTrailingYearAvg(where: { companyId: $id }) {\n      year\n      month\n      avg\n    }\n    company(id: $id) {\n      id\n      name\n      alerts {\n        id\n        name\n        email {\n          id\n          subject\n          body\n          receivedAt\n          nps\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ChurnDashboardQuery {\n    npsTrailingYearCounts {\n      year\n      month\n      low\n      med\n      high\n    }\n    problematicReps {\n      id\n      name\n      nps\n    }\n\n    atRiskCompanies {\n      id\n      name\n      nps\n    }\n  }\n"): (typeof documents)["\n  query ChurnDashboardQuery {\n    npsTrailingYearCounts {\n      year\n      month\n      low\n      med\n      high\n    }\n    problematicReps {\n      id\n      name\n      nps\n    }\n\n    atRiskCompanies {\n      id\n      name\n      nps\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RepDetailPage($id: ID!) {\n    person(id: $id) {\n      id\n      name\n      nps\n      emails {\n        id\n        subject\n        body\n        receivedAt\n        nps\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query RepDetailPage($id: ID!) {\n    person(id: $id) {\n      id\n      name\n      nps\n      emails {\n        id\n        subject\n        body\n        receivedAt\n        nps\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DemoSearchPageQuery($prompt: String!) {\n    searchEmails(prompt: $prompt) {\n      id\n      subject\n      body\n      receivedAt\n      nps\n      company {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query DemoSearchPageQuery($prompt: String!) {\n    searchEmails(prompt: $prompt) {\n      id\n      subject\n      body\n      receivedAt\n      nps\n      company {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateManyConnectionDrives($input: [UpdateConnectionDriveInput!]!) {\n    updateManyConnectionDrives(input: $input) {\n      id\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateManyConnectionDrives($input: [UpdateConnectionDriveInput!]!) {\n    updateManyConnectionDrives(input: $input) {\n      id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindConnection($id: ID!) {\n    connection(id: $id) {\n      ...MinimalConnection\n      drives {\n        id\n        name\n        url\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  query FindConnection($id: ID!) {\n    connection(id: $id) {\n      ...MinimalConnection\n      drives {\n        id\n        name\n        url\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateConnection($integrationId: IntegrationKey!) {\n    createConnection(integrationId: $integrationId) {\n      id\n      integrationKey\n      isAuthorized\n      integration {\n        id\n        connection {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateConnection($integrationId: IntegrationKey!) {\n    createConnection(integrationId: $integrationId) {\n      id\n      integrationKey\n      isAuthorized\n      integration {\n        id\n        connection {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteConnection($id: ID!) {\n    deleteConnection(id: $id) {\n      id\n      integration {\n        id\n        name\n        connection {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteConnection($id: ID!) {\n    deleteConnection(id: $id) {\n      id\n      integration {\n        id\n        name\n        connection {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReimportConnectionDrives($id: ID!) {\n    reimportConnectionDrives(id: $id) {\n      id\n      drives {\n        id\n        name\n        url\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ReimportConnectionDrives($id: ID!) {\n    reimportConnectionDrives(id: $id) {\n      id\n      drives {\n        id\n        name\n        url\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment Integration on Integration {\n  id\n  name\n  provider\n  requiredScope\n  connection {\n    ...MinimalConnection\n  }\n}"): (typeof documents)["fragment Integration on Integration {\n  id\n  name\n  provider\n  requiredScope\n  connection {\n    ...MinimalConnection\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment MinimalConnection on Connection {\n  id\n  isAuthorized\n}"): (typeof documents)["fragment MinimalConnection on Connection {\n  id\n  isAuthorized\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindIntegrations {\n    integrations {\n      ...Integration\n    }\n  }\n"): (typeof documents)["\n  query FindIntegrations {\n    integrations {\n      ...Integration\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AnswerCoworkerQuestion($id: ID!, $answer: String!) {\n    answerCoworkerQuestion(id: $id, answer: $answer) {\n      ...CoworkerQuestion\n    }\n  }\n"): (typeof documents)["\n  mutation AnswerCoworkerQuestion($id: ID!, $answer: String!) {\n    answerCoworkerQuestion(id: $id, answer: $answer) {\n      ...CoworkerQuestion\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCoworkerQuestion($id: ID!) {\n    deleteCoworkerQuestion(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteCoworkerQuestion($id: ID!) {\n    deleteCoworkerQuestion(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment CoworkerQuestion on CoworkerQuestion {\n  id\n  question\n  answer\n  askedBy {\n    id\n    email\n    name\n  }\n  answeredBy {\n    id\n    email\n    name\n  }\n  answeredAt\n}"): (typeof documents)["fragment CoworkerQuestion on CoworkerQuestion {\n  id\n  question\n  answer\n  askedBy {\n    id\n    email\n    name\n  }\n  answeredBy {\n    id\n    email\n    name\n  }\n  answeredAt\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CoworkerQuestions {\n    answered: coworkerQuestions(where: { isAnswered: true }) {\n      ...CoworkerQuestion\n    }\n    unanswered: coworkerQuestions(where: { isAnswered: false }) {\n      ...CoworkerQuestion\n    }\n  }\n"): (typeof documents)["\n  query CoworkerQuestions {\n    answered: coworkerQuestions(where: { isAnswered: true }) {\n      ...CoworkerQuestion\n    }\n    unanswered: coworkerQuestions(where: { isAnswered: false }) {\n      ...CoworkerQuestion\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindSearchRecordsForOrganization {\n    searchRecordsForOrganization {\n      id\n      createdAt\n      question\n      user {\n        id\n        name\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  query FindSearchRecordsForOrganization {\n    searchRecordsForOrganization {\n      id\n      createdAt\n      question\n      user {\n        id\n        name\n        email\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchRecordDetails($id: ID!) {\n    searchRecord(id: $id) {\n      id\n      createdAt\n      question\n      aiAnswer\n      user {\n        id\n        name\n        email\n      }\n      chunks {\n        id\n        markdown\n        cells {\n          id\n          text\n        }\n        spaceFile {\n          ...MinimalSpaceFile\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchRecordDetails($id: ID!) {\n    searchRecord(id: $id) {\n      id\n      createdAt\n      question\n      aiAnswer\n      user {\n        id\n        name\n        email\n      }\n      chunks {\n        id\n        markdown\n        cells {\n          id\n          text\n        }\n        spaceFile {\n          ...MinimalSpaceFile\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrganizationUsers {\n    usersForMyOrganization {\n      id\n      createdAt\n      lastActiveAt\n      email\n      name\n    }\n  }\n"): (typeof documents)["\n  query OrganizationUsers {\n    usersForMyOrganization {\n      id\n      createdAt\n      lastActiveAt\n      email\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Search($query: SearchQueryInput!) {\n    search(query: $query) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query Search($query: SearchQueryInput!) {\n    search(query: $query) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpacesForSearchBar {\n    spaces {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query SpacesForSearchBar {\n    spaces {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSearchRecordSentiment(\n    $id: ID!\n    $sentiment: SearchRecordSentiment!\n  ) {\n    updateSearchRecord(input: { id: $id, sentiment: $sentiment }) {\n      id\n      sentiment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSearchRecordSentiment(\n    $id: ID!\n    $sentiment: SearchRecordSentiment!\n  ) {\n    updateSearchRecord(input: { id: $id, sentiment: $sentiment }) {\n      id\n      sentiment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment AnsweredSearchRecord on SearchRecord {\n  id\n  aiAnswer\n  status\n}"): (typeof documents)["fragment AnsweredSearchRecord on SearchRecord {\n  id\n  aiAnswer\n  status\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment SearchBarRecord on SearchRecord {\n  id\n  question\n}"): (typeof documents)["fragment SearchBarRecord on SearchRecord {\n  id\n  question\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment SearchRecordChunk on SearchRecordChunk {\n  id\n  status\n  quotes\n  spaceFile {\n    ...MinimalSpaceFile\n  }\n}"): (typeof documents)["fragment SearchRecordChunk on SearchRecordChunk {\n  id\n  status\n  quotes\n  spaceFile {\n    ...MinimalSpaceFile\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment SearchRecordSentiment on SearchRecord {\n  id\n  sentiment\n}"): (typeof documents)["fragment SearchRecordSentiment on SearchRecord {\n  id\n  sentiment\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UnansweredQuestions {\n    unansweredQuestions: coworkerQuestions(where: { isAnswered: false }) {\n      ...CoworkerQuestion\n    }\n  }\n"): (typeof documents)["\n  query UnansweredQuestions {\n    unansweredQuestions: coworkerQuestions(where: { isAnswered: false }) {\n      ...CoworkerQuestion\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSearchRecord($id: ID!) {\n    searchRecord(id: $id) {\n      ...AnsweredSearchRecord\n      ...SearchBarRecord\n      ...SearchRecordSentiment\n      id\n      userId\n      question\n      chunks {\n        ...SearchRecordChunk\n      }\n      relatedQuestions {\n        ...CoworkerQuestion\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSearchRecord($id: ID!) {\n    searchRecord(id: $id) {\n      ...AnsweredSearchRecord\n      ...SearchBarRecord\n      ...SearchRecordSentiment\n      id\n      userId\n      question\n      chunks {\n        ...SearchRecordChunk\n      }\n      relatedQuestions {\n        ...CoworkerQuestion\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteSpaceFile($id: ID!) {\n    deleteSpaceFile(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteSpaceFile($id: ID!) {\n    deleteSpaceFile(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReprocessSPaceFile($id: ID!) {\n    reprocessSpaceFile(id: $id) {\n      id\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation ReprocessSPaceFile($id: ID!) {\n    reprocessSpaceFile(id: $id) {\n      id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReingestSpaceFile($id: ID!) {\n    reingestSpaceFile(id: $id) {\n      id\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation ReingestSpaceFile($id: ID!) {\n    reingestSpaceFile(id: $id) {\n      id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment MinimalSpaceFile on SpaceFile {\n  id\n  path\n  originalMimeType\n  name\n  source\n  sourceId\n  status\n  url\n}"): (typeof documents)["fragment MinimalSpaceFile on SpaceFile {\n  id\n  path\n  originalMimeType\n  name\n  source\n  sourceId\n  status\n  url\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SaveNote($input: SaveNoteInput!) {\n    saveNote(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation SaveNote($input: SaveNoteInput!) {\n    saveNote(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpsertManualSpace($name: String!) {\n    upsertManualSpace(input: { name: $name }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpsertManualSpace($name: String!) {\n    upsertManualSpace(input: { name: $name }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpsertUrlSpace($input: UpsertUrlSpaceInput!) {\n    upsertUrlSpace(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpsertUrlSpace($input: UpsertUrlSpaceInput!) {\n    upsertUrlSpace(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpaceDetails($id: ID!) {\n    space(id: $id) {\n      id\n      name\n      sourceId\n      source\n      files {\n        ...MinimalSpaceFile\n      }\n    }\n  }\n"): (typeof documents)["\n  query SpaceDetails($id: ID!) {\n    space(id: $id) {\n      id\n      name\n      sourceId\n      source\n      files {\n        ...MinimalSpaceFile\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MySpaces {\n    spaces {\n      id\n      name\n      source\n    }\n  }\n"): (typeof documents)["\n  query MySpaces {\n    spaces {\n      id\n      name\n      source\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteSpace($id: ID!) {\n    deleteSpace(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteSpace($id: ID!) {\n    deleteSpace(id: $id)\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;