import { useLazyQuery } from "@apollo/client";
import { AuthenticationInfo } from "@propelauth/javascript";
import * as Sentry from "@sentry/react";
import { createContext, useEffect, useState } from "react";
import { FaPersonCircleCheck } from "react-icons/fa6";
import { graphql } from "../gql";
import { waitMs } from "../utils/async.utils";
import { Auth } from "../utils/auth.utils";
import { Card, CardBody, CardHeader, CardTitle } from "./Card";
import { PageLoader } from "./Page";
import { Spinner } from "./Spinner";

const getUserIsReady = graphql(`
  query UserIsReady {
    userIsReady
  }
`);

// Convenience hack: We're guaranteeing that the context is set by anyone accessing it.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthContext = createContext<AuthenticationInfo | null>(null);

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [authInfo, setAuthInfo] = useState<AuthenticationInfo | null>(null);
  const [isProvisioning, setIsProvisioning] = useState(false);

  const [loadUserIsReady, { data: { userIsReady = false } = {} }] =
    useLazyQuery(getUserIsReady);

  useEffect(() => {
    const authInfoFn = () => {
      void Auth.client.getAuthenticationInfoOrNull().then(setAuthInfo);
    };

    void Auth.client.getAuthenticationInfoOrNull(true).then(async (val) => {
      if (val) {
        Sentry.setUser({ id: val.user.userId, email: val.user.email });
        const response = await loadUserIsReady();
        let provisioned = response.data?.userIsReady;
        if (provisioned) {
          return;
        }
        setIsProvisioning(true);
        while (provisioned !== true) {
          await waitMs(500);
          provisioned = (await loadUserIsReady({ fetchPolicy: "network-only" }))
            .data?.userIsReady;
        }
        setIsProvisioning(false);
      } else {
        Auth.client.redirectToLoginPage();
      }
    });
    // Auth.client.addLoggedInChangeObserver(loggedInFn);
    Auth.client.addAccessTokenChangeObserver(authInfoFn);

    return () => {
      Auth.client.removeAccessTokenChangeObserver(authInfoFn);
      // Auth.client.removeLoggedInChangeObserver(loggedInFn);
    };
  }, []);

  if (isProvisioning) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Card className="mt-12">
          <CardHeader className="flex justify-between">
            <CardTitle title="Success" className="text-emerald-700" />
            <Spinner />
          </CardHeader>
          <CardBody className="flex items-center gap-4">
            <FaPersonCircleCheck className="text-emerald-600 text-6xl" />
            <p>Please wait a moment while we set up your account.</p>
          </CardBody>
        </Card>
      </div>
    );
  } else if (!authInfo || !userIsReady) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};
