import { RouteObject } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { apiRememberMe } from "../../utils/api.utils";

export const oauthRoutes: RouteObject[] = [
  {
    children: [
      {
        path: "authorize/:provider",
        loader: async ({ params, request }) => {
          const url = new URL(request.url);
          await apiRememberMe(
            `/oauth/authorize/${params.provider}${url.search}`
          );
          return null;
        },
        element: (
          <div>
            {" "}
            <Spinner />
            Redirecting
          </div>
        ),
      },
    ],
  },
];
