import { RouteObject, redirect } from "react-router-dom";
import { OrganizationRoot } from "./OrganizationRoot";
import { OrganizationUsersPage } from "./pages/OrganizationUsersPage";

export const organizationRoutes: RouteObject[] = [
  {
    element: <OrganizationRoot />,
    children: [
      {
        index: true,
        loader: () => redirect("./users"),
      },
      {
        path: "users",
        element: <OrganizationUsersPage />,
      },
    ],
  },
];
