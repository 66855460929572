import { useSuspenseQuery } from "@apollo/client";
import { Card, CardBody } from "../../../components/Card";
import { DateTime } from "../../../components/DateTime";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { graphql } from "../../../gql";
import { formatDate } from "../../../utils/date.utils";

const organizationUsers = graphql(`
  query OrganizationUsers {
    usersForMyOrganization {
      id
      createdAt
      lastActiveAt
      email
      name
    }
  }
`);

export const OrganizationUsersPage: React.FC = () => {
  const {
    data: { usersForMyOrganization },
  } = useSuspenseQuery(organizationUsers);

  return (
    <div className="flex flex-wrap gap-4">
      <Card className="w-full">
        <CardBody>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Signed Up On</TableHead>
                <TableHead>Last Active At</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {usersForMyOrganization.map((user) => (
                <TableRow key={user.id}>
                  <TableCell className="flex flex-col">
                    <strong>{user.name}</strong>
                    {user.email}
                  </TableCell>
                  <TableCell>
                    <DateTime
                      datetime={user.createdAt}
                      formatter={formatDate}
                    />
                  </TableCell>
                  <TableCell>
                    <DateTime datetime={user.lastActiveAt} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
      </Card>

      {/* <Card>
        <CardHeader>
          <CardTitle icon={<FaRobot />} title="AI Answer" />
        </CardHeader>
        <CardBody>
          <ReactMarkdown className="prose">
            {searchRecord.aiAnswer}
          </ReactMarkdown>
        </CardBody>
      </Card>

      {searchRecord.chunks.map((chunk) => (
        <div className="flex gap-4" key={chunk.id}>
          <Card className="flex-1">
            <CardHeader className="flex justify-between items-center">
              <CardTitle title={chunk.spaceFile.name} />
              <SpaceFileStylizedLink file={chunk.spaceFile}>
                Download
              </SpaceFileStylizedLink>
            </CardHeader>
            <CardBody>
              <ReactMarkdown className="prose">{chunk.markdown}</ReactMarkdown>
            </CardBody>
          </Card>

          <Card className="flex-1">
            <CardBody>
              <ul className="list-disc text-sm flex flex-col gap-1 ml-4">
                {chunk.cells.map((cell) => (
                  <li key={cell.id}>{cell.text}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </div>
      ))} */}
    </div>
  );
};
