import { useSuspenseQuery } from "@apollo/client";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { PageContent, PageHeader } from "../../../components/Page";
import { buttonVariants } from "../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { graphql } from "../../../gql";
import { formatDateFromString } from "../../../utils/date.utils";

const pageQuery = graphql(`
  query RepDetailPage($id: ID!) {
    person(id: $id) {
      id
      name
      nps
      emails {
        id
        subject
        body
        receivedAt
        nps
        company {
          id
          name
        }
      }
    }
  }
`);

function formatAvg(number: number, decimalPlaces = 1) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}

export const RepDetailPage = () => {
  const { employeeId } = useParams();
  const {
    data: { person },
  } = useSuspenseQuery(pageQuery, { variables: { id: employeeId! } });
  return (
    <>
      <PageHeader title={person.name}></PageHeader>
      <PageContent></PageContent>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <CardTitle
            title="Emails"
            // icon={<FaExclamationCircle className="text-red-600" />}
          />
        </CardHeader>
        <CardBody>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Company</TableHead>
                <TableHead>Received At</TableHead>
                <TableHead>NPS</TableHead>
                <TableHead>Email</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {person.emails.map((email) => (
                <TableRow>
                  <TableCell className="align-top">
                    <Link
                      className={buttonVariants({ variant: "link" })}
                      to={`/dashboards/${email.company.id}`}
                    >
                      {email.company.name}
                    </Link>
                  </TableCell>
                  <TableCell className="align-top">
                    {formatDateFromString(email.receivedAt)}
                  </TableCell>
                  <TableCell className="align-top">{email.nps}</TableCell>
                  <TableCell className="">
                    <div>
                      <strong>Subject:</strong> {email.subject}
                    </div>
                    <ReactMarkdown className="prose prose-sm">
                      {email.body}
                    </ReactMarkdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};
