import { formatISO, parseISO } from "date-fns";
import { formatDateTime } from "../utils/date.utils";

export const DateTime: React.FC<{
  datetime: Date | string | null | undefined;
  formatter?: (date: Date) => string;
}> = ({ datetime, formatter = formatDateTime }) => {
  if (!datetime) {
    return <em>N/a</em>;
  }
  const date = typeof datetime === "string" ? parseISO(datetime) : datetime;

  return <time dateTime={formatISO(date)}>{formatter(date)}</time>;
};
