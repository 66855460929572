import { FaExclamationCircle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { Card, CardBody, CardHeader } from "../../../components/Card";
import { Spinner } from "../../../components/Spinner";
import {
  SearchRecordChunkFragment,
  SearchRecordChunkStatus,
} from "../../../gql/graphql";
import { SpaceFileStylizedLink } from "../../spaces/components/SpaceFileLink";

export const SearchRecordChunkCard: React.FC<{
  chunk: SearchRecordChunkFragment;
}> = ({ chunk }) => {
  let icon: React.ReactNode;
  if (chunk.status === SearchRecordChunkStatus.QuotesGenerated) {
    icon = <FaCheck className="text-primary" />;
  } else if (chunk.status === SearchRecordChunkStatus.Error) {
    icon = <FaExclamationCircle className="text-amber-600" />;
  } else {
    icon = <Spinner />;
  }

  return (
    <Card>
      <CardHeader sticky className="flex justify-between">
        <SpaceFileStylizedLink file={chunk.spaceFile}>
          {chunk.spaceFile.name}
        </SpaceFileStylizedLink>
        {icon}
      </CardHeader>
      <CardBody className="prose-sm whitespace-pre-line">
        {chunk.quotes}
      </CardBody>
    </Card>
  );
};
