import { useMutation } from "@apollo/client";
import { FaEllipsisV } from "react-icons/fa";
import { FaArrowsRotate, FaLinkSlash } from "react-icons/fa6";
import { toast } from "sonner";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { graphql } from "../../../gql";
import { IntegrationFragment } from "../../../gql/graphql";
import { ConnectionRenderer } from "./ConnectionRenderer";
import { CreateConnectionButton } from "./CreateConnectionButton";

export const IntegrationCard: React.FC<{
  integration: IntegrationFragment;
}> = ({ integration }) => {
  return (
    <Card>
      <CardHeader className="flex justify-between">
        <CardTitle title={integration.name} />
        <IntegrationCardControls integration={integration} />
      </CardHeader>
      <CardBody className="flex justify-center">
        {integration.connection ? (
          <ConnectionRenderer
            connection={integration.connection}
            integration={integration}
          />
        ) : (
          <CreateConnectionButton integration={integration} />
        )}
      </CardBody>
    </Card>
  );
};

const deleteConnectionMutation = graphql(`
  mutation DeleteConnection($id: ID!) {
    deleteConnection(id: $id) {
      id
      integration {
        id
        name
        connection {
          id
        }
      }
    }
  }
`);

const reimportConnectionDrivesMutation = graphql(`
  mutation ReimportConnectionDrives($id: ID!) {
    reimportConnectionDrives(id: $id) {
      id
      drives {
        id
        name
        url
        status
      }
    }
  }
`);

const IntegrationCardControls: React.FC<{
  integration: IntegrationFragment;
}> = ({ integration }) => {
  const [deleteConnection] = useMutation(deleteConnectionMutation, {
    onError: () => toast.error("An error occurred"),
    onCompleted: () => toast.success(`${integration.name} disconnected`),
  });
  const [reimportDrives, { loading }] = useMutation(
    reimportConnectionDrivesMutation
  );
  const { connection } = integration;
  if (!connection) {
    return null;
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <FaEllipsisV />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() => {
            reimportDrives({
              variables: {
                id: connection.id,
              },
            });
          }}
        >
          <FaArrowsRotate className="mr-1" />
          Re Import
        </DropdownMenuItem>

        <DropdownMenuItem
          onClick={() => {
            deleteConnection({
              variables: {
                id: connection.id,
              },
            });
          }}
        >
          <FaLinkSlash className="mr-1" />
          Disconnect
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
