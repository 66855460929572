import { RouteObject, redirect } from "react-router-dom";
import { KnowledgeRoot } from "./KnowledgeRoot";
import { CoworkerQuestionsPage } from "./pages/CoworkerQuestionsPage";
import { SearchDashboardPage } from "./pages/SearchDashboardPage";
import { SearchDetailsPage } from "./pages/SearchDetailsPage";

export const knowledgeRoutes: RouteObject[] = [
  {
    element: <KnowledgeRoot />,
    children: [
      {
        index: true,
        loader: () => redirect("./questions"),
      },
      {
        path: "questions",
        element: <CoworkerQuestionsPage />,
      },
      {
        path: "search",
        element: <SearchDashboardPage />,
      },
      {
        path: "search/:searchRecordId",
        element: <SearchDetailsPage />,
      },
    ],
  },
];
