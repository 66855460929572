import { useSuspenseQuery } from "@apollo/client";
import { PageContent } from "../../../components/Page";
import { graphql } from "../../../gql";
import { IntegrationKey } from "../../../gql/graphql";
import { IntegrationCard } from "../components/IntegrationCard";

const integrationsQuery = graphql(`
  query FindIntegrations {
    integrations {
      ...Integration
    }
  }
`);

export const ConnectionsIndex = () => {
  return (
    <PageContent>
      <IntegrationsList />
    </PageContent>
  );
};

const IntegrationsList = () => {
  const { data } = useSuspenseQuery(integrationsQuery);
  const { integrations } = data;

  return (
    <div className="flex gap-4">
      {integrations
        .filter(
          (integration) =>
            integration.id === IntegrationKey.MsSharepoint ||
            integration.id === IntegrationKey.MsOutlook
        )
        .map((integration) => (
          <IntegrationCard integration={integration} key={integration.id} />
        ))}
    </div>
  );
};
