const sort = <T>(val: Iterable<T>, compareFn?: (a: T, b: T) => number) => {
  const newArray = [...val];
  newArray.sort(compareFn);
  return newArray;
};

export function* range(stop: number, start = 0, step = 1) {
  if (step < 1) {
    throw new Error("Step must be >1 for loop to terminate");
  }
  for (let i = start; i < stop; i += step) {
    yield i;
  }
}

export const Arrays = {
  sort,
};
