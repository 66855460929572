import { useFormState } from "react-hook-form";
import { cn } from "../../lib/utils";
import { Spinner } from "../Spinner";
import { Button, ButtonProps } from "../ui/button";

export const BoundButton: React.FC<
  ButtonProps & {
    icon?: React.ReactNode;
    iconAtEnd?: boolean;
    component?: React.FC<ButtonProps> | string;
  }
> = ({
  children,
  icon,
  iconAtEnd,
  className,
  disabled: givenDisabled,
  component: Component = Button,
  ...props
}) => {
  const {
    disabled: formDisabled,
    isSubmitting,
    isValid,
    isDirty,
  } = useFormState();

  let iconNode: React.ReactNode = undefined;
  if (isSubmitting) {
    iconNode = <Spinner />;
  } else if (icon) {
    iconNode = (
      <span className={cn("flex items-center", iconAtEnd ? "ml-2" : "mr-2")}>
        {icon}
      </span>
    );
  }

  return (
    <Component
      className={cn("flex items-center", className)}
      type="submit"
      disabled={
        givenDisabled || formDisabled || isSubmitting || !isValid || !isDirty
      }
      {...props}
    >
      {!iconAtEnd && iconNode} {children} {iconAtEnd && iconNode}
    </Component>
  );
};
