import { RouteObject } from "react-router-dom";
import { AtRiskPage } from "./pages/AtRiskPage";
import { ChurnDetailPage } from "./pages/ChurnDetailPage";
import { ChurnIndexPage } from "./pages/ChurnIndexPage";
import { RepDetailPage } from "./pages/RepDetailPage";
import { SearchResultPage } from "./pages/SearchResultPage";

export const churnRoutes: RouteObject[] = [
  {
    // element: <ChurnRoot />,
    children: [
      { index: true, element: <ChurnIndexPage /> },
      { path: "at-risk", element: <AtRiskPage /> },
      { path: "search", element: <SearchResultPage /> },
      { path: ":companyId", element: <ChurnDetailPage /> },
      { path: "employee/:employeeId", element: <RepDetailPage /> },
    ],
  },
];
