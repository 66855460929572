import { PropsWithChildren, Suspense } from "react";
import {
  FaBuilding,
  FaFolderOpen,
  FaGear,
  FaMagnifyingGlass,
  FaPersonCircleQuestion,
} from "react-icons/fa6";
import { PiPlugsBold } from "react-icons/pi";
import { NavLink } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { cn } from "~/lib/utils";
import { Auth } from "../utils/auth.utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

export const Sidebar = () => {
  return (
    <div className="sticky top-0 h-screen p-1 px-2">
      <nav className="flex h-full w-12 flex-col self-start overflow-auto bg-white text-primary shadow rounded">
        <SidebarLink to="/search">
          <FaMagnifyingGlass />
        </SidebarLink>

        <SidebarLink to="/spaces">
          <FaFolderOpen />
        </SidebarLink>
        <SidebarLink to="/connections">
          <PiPlugsBold />
        </SidebarLink>
        <SidebarLink to="/knowledge">
          <FaPersonCircleQuestion />
        </SidebarLink>

        <SidebarLink to="/organization">
          <FaBuilding />
        </SidebarLink>
        {/* <SidebarLink to="/admin">
          <FaShieldHalved />
        </SidebarLink> */}

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button
              type="button"
              className="flex w-full items-center justify-center py-4 transition hover:bg-primary/10 mt-auto"
            >
              <FaGear />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <a
              href={Auth.client.getAccountPageUrl()}
              target="_blank"
              className="w-full"
            >
              <DropdownMenuItem className="cursor-pointer">
                Settings / API Keys
              </DropdownMenuItem>
            </a>
            <DropdownMenuItem
              onClick={() => Auth.client.logout(true)}
              className="cursor-pointer"
            >
              Logout
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </nav>
    </div>
  );
};

const SidebarLink: React.FC<{ to: string; children?: React.ReactNode }> = ({
  to,
  children,
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        cn(
          "flex w-12 items-center justify-center py-4 transition hover:bg-primary/10",
          {
            loading: isPending,
            "bg-primary/10": isActive,
          }
        )
      }
    >
      {children}
    </NavLink>
  );
};

export const PageLoader = () => (
  <div className="w-full h-full flex items-center justify-center">
    <ScaleLoader color="hsl(var(--primary))" height={50} width={6} />
  </div>
);

export const PageContent: React.FC<
  PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <main className={cn("flex-1", className)}>
    <Suspense fallback={<PageLoader />}>{children}</Suspense>
  </main>
);

export const PageHeader: React.FC<
  PropsWithChildren<{ title: string; subtitle?: string; className?: string }>
> = ({ children, title, subtitle, className }) => (
  <header
    className={cn(
      "bg-white shadow rounded w-full flex items-center p-3 py-1 mb-3 justify-between min-h-12",
      className
    )}
  >
    <PageHeaderTitle title={title} subtitle={subtitle} />
    {children}
  </header>
);

export const PageHeaderNav: React.FC<
  PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <nav className={cn("flex-1 flex mx-8 text-sm gap-4", className)}>
    {children}
  </nav>
);
export const PageHeaderLink: React.FC<
  PropsWithChildren<{ to: string; className?: string }>
> = ({ to, children, className }) => (
  <NavLink
    className={({ isActive }) =>
      cn("hover:text-primary transition-colors", {
        "text-primary underline underline-offset-4": isActive,
        className,
      })
    }
    to={to}
  >
    {children}
  </NavLink>
);

const PageHeaderTitle: React.FC<{
  title: string;
  subtitle?: string;
}> = ({ subtitle, title }) => (
  <h1 className="font-medium text-zinc-900 flex items-center">
    {title}
    {subtitle && <>: {subtitle}</>}
  </h1>
);
