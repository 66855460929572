
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ContentBlock": [
      "HeaderContentBlock",
      "ListContentBlock",
      "ListItemContentBlock",
      "ParagraphContentBlock"
    ]
  }
};
      export default result;
    