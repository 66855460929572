import { useSuspenseQuery } from "@apollo/client";
import { useEffect } from "react";
import { z } from "zod";
import { PageHeader } from "../../../components/Page";
import { graphql } from "../../../gql";
import {
  SearchRecordChunkFragment,
  SearchRecordStatus,
} from "../../../gql/graphql";
import { useParamsStrict } from "../../../hooks/useParamsStrict";
import { CoworkerQuestionCard } from "../../knowledge/components/CoworkersQuestionCard";
import { SearchRecordAnswerCard } from "../components/SearchRecordAnswerCard";
import { SearchRecordChunkCard } from "../components/SearchRecordChunkCard";
import { triggerStream } from "../streamManager";

const getSearchRecordDocument = graphql(`
  query GetSearchRecord($id: ID!) {
    searchRecord(id: $id) {
      ...AnsweredSearchRecord
      ...SearchBarRecord
      ...SearchRecordSentiment
      id
      userId
      question
      chunks {
        ...SearchRecordChunk
      }
      relatedQuestions {
        ...CoworkerQuestion
      }
    }
  }
`);

const ParamSchema = z.object({
  searchRecordId: z.string(),
});

export const SearchResultPage: React.FC = () => {
  const { searchRecordId } = useParamsStrict(ParamSchema);
  const {
    data: { searchRecord },
  } = useSuspenseQuery(getSearchRecordDocument, {
    variables: { id: searchRecordId },
  });

  const shouldStream = searchRecord.status === SearchRecordStatus.Pending;

  useEffect(() => {
    if (shouldStream) {
      triggerStream(searchRecordId);
    }
  }, [searchRecordId, shouldStream]);

  const chunks = [...searchRecord.chunks].sort(chunkSortOrder);

  return (
    <div className="flex gap-4">
      <section className="flex flex-col gap-4 flex-1">
        <PageHeader title="Documents & Quotes" className="mb-0"></PageHeader>
        {searchRecord.relatedQuestions.map((question) => (
          <CoworkerQuestionCard question={question} hideControls />
        ))}
        {chunks.map((chunk) => (
          <SearchRecordChunkCard chunk={chunk} />
        ))}
      </section>
      <div className="flex-1 h-full relative">
        <SearchRecordAnswerCard searchRecord={searchRecord} />
      </div>
    </div>
  );
};

const chunkSortOrder = (
  c1: SearchRecordChunkFragment,
  c2: SearchRecordChunkFragment
) => {
  if (c2.quotes.toLocaleLowerCase() == "n/a") {
    return -1;
  } else if (c1.quotes.toLocaleLowerCase() == "n/a") {
    return 1;
  } else {
    return 0;
  }
};
