import { RouteObject } from "react-router-dom";
import { SearchRoot } from "./SearchRoot";
import { SearchIndexPage } from "./pages/SearchIndexPage";
import { SearchResultPage } from "./pages/SearchResultPage";

export const searchRoutes: RouteObject[] = [
  {
    element: <SearchRoot />,
    children: [
      { index: true, element: <SearchIndexPage /> },
      {
        path: ":searchRecordId",
        element: <SearchResultPage />,
      },
    ],
  },
];
