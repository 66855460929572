import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { Card, CardBody, CardFooter } from "../../../components/Card";
import { PageContent, PageHeader } from "../../../components/Page";
import { Reform } from "../../../components/Reform";
import { BoundButton } from "../../../components/forms/BoundButton";
import { Bound } from "../../../components/forms/boundComponents";
import { graphql } from "../../../gql";

export const NewUrlSpacePage: React.FC = () => {
  return (
    <>
      <PageHeader title="Spaces" subtitle="Scrape a URL" />
      <PageContent>
        <NewSpaceForm />
      </PageContent>
    </>
  );
};

const newSpaceFormSchema = z.object({
  name: z.string().min(1, "Must have a name"),
  url: z.string(),
});

const upsertUrlSpace = graphql(`
  mutation UpsertUrlSpace($input: UpsertUrlSpaceInput!) {
    upsertUrlSpace(input: $input) {
      id
    }
  }
`);

const NewSpaceForm: React.FC = () => {
  const navigate = useNavigate();
  const [upsertSpace] = useMutation(upsertUrlSpace, {
    onCompleted: ({ upsertUrlSpace }) =>
      navigate(`/spaces/${upsertUrlSpace.id}`),
    onError: () => toast.error("An error occurred"),
  });
  return (
    <Reform
      schema={newSpaceFormSchema}
      onSubmit={(input) => upsertSpace({ variables: { input } })}
    >
      <Card>
        <CardBody>
          <Bound.Label name="name">Name</Bound.Label>
          <Bound.Input name="name" />

          <Bound.Label name="url" className="mt-4">
            URL
          </Bound.Label>
          <Bound.Input name="url" type="url" />
        </CardBody>
        <CardFooter className="flex justify-end">
          <BoundButton>Save</BoundButton>
        </CardFooter>
      </Card>
    </Reform>
  );
};
