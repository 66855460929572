import { registerFormInput } from "./formBinding";

import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { BoundButton } from "./BoundButton";
import { BoundLabel } from "./BoundLabel";
import { BoundSwitch } from "./BoundSwitch";

export const Bound = {
  Switch: BoundSwitch,
  Textarea: registerFormInput(Textarea),
  Input: registerFormInput(Input),
  Button: BoundButton,
  Label: BoundLabel,
};
