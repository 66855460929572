import { useSuspenseQuery } from "@apollo/client";
import { FaExclamationTriangle } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import {
  CartesianGrid,
  Dot,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Button } from "../../../components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../components/ui/collapsible";
import { graphql } from "../../../gql";

import { format } from "date-fns";
import { uniq } from "lodash";
import ReactMarkdown from "react-markdown";
import { PageContent, PageHeader } from "../../../components/Page";
import { isDefined } from "./untils";

const CustomDot = (fn: (payload: any) => boolean) => (props: any) => {
  const { cx, cy, payload } = props;
  // Customize the dot for the specific data point
  if (fn(payload)) {
    return (
      <g>
        <text x={cx} y={cy - 10} textAnchor="middle" fill="#ff7300">
          !
        </text>
        <Dot {...props} r={5} fill="#ff7300" />
      </g>
    );
  }

  return <Dot {...props} />;
};

const detailQuery = graphql(`
  query ChunDetailPage($id: ID!) {
    npsTrailingYearAvg(where: { companyId: $id }) {
      year
      month
      avg
    }
    company(id: $id) {
      id
      name
      alerts {
        id
        name
        email {
          id
          subject
          body
          receivedAt
          nps
        }
      }
    }
  }
`);

function formatAvg(number: number, decimalPlaces = 1) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}
export const ChurnDetailPage = () => {
  const { companyId } = useParams();
  const {
    data: { npsTrailingYearAvg, company },
  } = useSuspenseQuery(detailQuery, { variables: { id: companyId! } });

  const problemMonths = uniq(
    company.alerts
      .map((alert) => alert.email?.receivedAt)
      .filter(isDefined)
      .map((d) => format(d, "yyyy/M"))
  );
  return (
    <>
      <PageHeader title={`${company.name} Details`}></PageHeader>
      <PageContent>
        <div className="flex gap-4 flex-wrap">
          <section className="gap-4 flex flex-col">
            <Card>
              <CardHeader>
                <CardTitle title="MoM NPS" />
              </CardHeader>
              <CardBody className="px-4">
                <LineChart
                  width={700}
                  height={400}
                  data={npsTrailingYearAvg.map((v) => ({
                    ...v,
                    avg: formatAvg(v.avg),
                  }))}
                >
                  <Legend
                    verticalAlign="top"
                    height={36}
                    formatter={(val) => {
                      return <span className="text-slate-600 mr-2">{val}</span>;
                    }}
                    iconType="circle"
                    iconSize={6}
                  />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <Tooltip />
                  <XAxis dataKey={(r) => `${r.year}/${r.month}`} />
                  <YAxis domain={[0, 10]} tickCount={10} />
                  <Line
                    type="monotone"
                    dataKey="avg"
                    name="Average NPS"
                    dot={CustomDot((payload) => {
                      const check = `${payload.year}/${payload.month}`;
                      console.log(
                        check,
                        problemMonths,
                        problemMonths.includes(check)
                      );
                      return problemMonths.includes(check);
                    })}
                  />
                </LineChart>
              </CardBody>
            </Card>
          </section>

          <section className="gap-4 flex flex-col flex-1">
            {company.alerts.filter((alert) => alert.name == "Low NPS Score")
              .length != 0 && (
              <h3>
                <Card>
                  <CardHeader>
                    <CardTitle
                      title="Low NPS Score"
                      icon={<FaExclamationTriangle />}
                    />
                  </CardHeader>
                </Card>
              </h3>
            )}
            {company.alerts.map(
              (alert) =>
                alert.email && (
                  <Card>
                    <Collapsible>
                      <CardHeader className="flex justify-between items-center">
                        <CardTitle
                          title={alert.email.subject}
                          subtitle={`NPS: ${alert.email.nps}`}
                          // subtitle="2 Alerts"
                          icon={<FaEnvelope />}
                          // icon={<FaExclamationTriangle className="text-orange-600" />}
                        />
                        <CollapsibleTrigger>
                          <Button variant="outline">Read Email</Button>
                        </CollapsibleTrigger>
                      </CardHeader>
                      <CardBody>
                        <header className="flex items-center text-sm gap-1 text-zinc-600 justify-center mt-4 mb-1">
                          <FaExclamationTriangle />
                          <h3>Alerts</h3>
                        </header>
                        <ul className="flex flex-col gap-2">
                          <li className="bg-zinc-50 border-l border-zinc-300 p-2">
                            {alert.name}
                          </li>
                        </ul>

                        <CollapsibleContent>
                          <hr className="mt-4 mb-3" />
                          {/* <Separator className="w-full mt-6" /> */}
                          <div>
                            <strong>Subject:</strong> {alert.email.subject}
                          </div>
                          <strong>Body:</strong>
                          <div className="p-2 border border-zinc-100 bg-zinc-50 flex flex-col gap-4">
                            <ReactMarkdown>{alert.email.body}</ReactMarkdown>
                          </div>
                        </CollapsibleContent>
                      </CardBody>
                    </Collapsible>
                  </Card>
                )
            )}
          </section>
        </div>
      </PageContent>
    </>
  );
};
