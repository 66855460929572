import React from "react";
import { FaCircleExclamation } from "react-icons/fa6";

interface Props {
  children?: React.ReactNode;
}

export class ErrorBoundary extends React.Component<
  Props,
  { hasError: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, info) {
  // Example "componentStack":
  //   in ComponentThatThrows (created by App)
  //   in ErrorBoundary (created by App)
  //   in div (created by App)
  //   in App
  // logErrorToMyService(error, info.componentStack);
  // }

  render() {
    return this.state.hasError ? (
      <div className="flex flex-col w-full h-full justify-center items-center min-h-80">
        <p className="text-red-800">We're sorry, an error occurred.</p>
        <FaCircleExclamation className="w-20 h-20 text-red-700 mt-4" />
      </div>
    ) : (
      this.props.children
    );
  }
}

export const ErrorPage = () => (
  <div className="flex flex-col w-full h-full justify-center items-center min-h-80">
    <p className="text-red-800">We're sorry, an error occurred.</p>
    <FaCircleExclamation className="w-20 h-20 text-red-700 mt-4" />
  </div>
);
