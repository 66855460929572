import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";

export const CreateSpaceButton = () => (
  // <Link to="/spaces/new/manual" className={buttonVariants()}>
  //   New Space <FaPlusCircle className="ml-2" />
  // </Link>
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button type="button">
        New Space <FaPlusCircle className="ml-2" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent>
      {/* <DropdownMenuLabel>Google Integrations</DropdownMenuLabel>
      <DropdownMenuItem>
        <Link to="/connections" className="w-full">
          Google Drive
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <Link to="/connections" className="w-full">
          One Drive
        </Link>
      </DropdownMenuItem> */}
      <DropdownMenuItem>
        <Link to="/spaces/new/manual" className="w-full">
          Manually Managed
        </Link>
      </DropdownMenuItem>
      {/* <DropdownMenuItem>
        <Link to="/spaces/new/url" className="w-full">
          Scrape URL
        </Link>
      </DropdownMenuItem> */}
      {/* {organizationId === "clno08aas00000351nna2029n" && (
      <DropdownMenuItem>
        <Link to="/spaces/create/gmail" className="w-full">
          Gmail
        </Link>
      </DropdownMenuItem>
      )} */}
    </DropdownMenuContent>
  </DropdownMenu>
);
