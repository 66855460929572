import { RouteObject } from "react-router-dom";
import { CreateNotePage } from "./pages/CreateNotePage";
import { NewManualSpacePage } from "./pages/NewManualSpacePage";
import { NewUrlSpacePage } from "./pages/NewURLSpacePage";
import { SpaceDetailPage } from "./pages/SpaceDetailsPage";
import { SpaceDetailRoot } from "./pages/SpaceDetailsRoot";
import { SpaceListPage } from "./pages/SpaceListPage";

export const spaceRoutes: RouteObject[] = [
  {
    children: [
      { index: true, element: <SpaceListPage /> },
      {
        path: "new",
        children: [
          { path: "manual", element: <NewManualSpacePage /> },
          { path: "url", element: <NewUrlSpacePage /> },
        ],
      },
      {
        path: ":spaceId",
        element: <SpaceDetailRoot />,
        children: [
          { index: true, element: <SpaceDetailPage /> },
          { path: "new-note", element: <CreateNotePage /> },
        ],
      },
    ],
  },
];
