import { useSuspenseQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { SpaceFileViewer } from "../components/FileViewer";
import { spaceDetailsQuery } from "./SpaceDetailsRoot";

const ParamSchema = z.object({
  spaceId: z.string(),
});

export const SpaceDetailPage = () => {
  const params = ParamSchema.parse(useParams());
  const {
    data: { space },
  } = useSuspenseQuery(spaceDetailsQuery, {
    variables: {
      id: params.spaceId,
    },
    fetchPolicy: "cache-and-network",
  });
  return (
    <section className="bg-white shadow rounded">
      <SpaceFileViewer files={space.files} />
    </section>
  );
};
