import { useLazyQuery } from "@apollo/client";
import ReactJson from "react-json-view";
import { z } from "zod";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Reform } from "../../../components/Reform";
import { Bound } from "../../../components/forms/boundComponents";
import { graphql } from "../../../gql";
import { GetSearchContentBlocksQuery } from "../../../gql/graphql";
import { Maps } from "../../../utils/maps.utils";

const getSearchContentBlocksQuery = graphql(`
  query GetSearchContentBlocks($prompt: String!) {
    searchContentBlocks(prompt: $prompt) {
      contentBlocks {
        ... on HeaderContentBlock {
          id
          text
          parentId
        }
        ... on ListContentBlock {
          id
          parentId
        }
        ... on ListItemContentBlock {
          id
          text
          parentId
        }
        ... on ParagraphContentBlock {
          id
          text
          parentId
        }
      }
      documents {
        spaceFile {
          id
          path
        }
      }
      query {
        namedEntities
        phrases
      }
    }
  }
`);

const formSchema = z.object({
  prompt: z.string(),
});

export const SearchContentBlockAdmin: React.FC = () => {
  const [getQuery, { loading, data }] = useLazyQuery(
    getSearchContentBlocksQuery,
    {
      fetchPolicy: "network-only",
    }
  );

  const rootBlocks = data?.searchContentBlocks
    ? rebuildHierarchy(data.searchContentBlocks.contentBlocks)
    : [];

  return (
    <div className="flex flex-col gap-4">
      <Reform
        schema={formSchema}
        onSubmit={({ prompt }) => getQuery({ variables: { prompt } })}
      >
        <Card>
          <CardBody>
            <Bound.Label name="prompt">Prompt</Bound.Label>
            <Bound.Textarea name="prompt" required />
          </CardBody>
          <CardFooter>
            <Bound.Button disabled={loading}>Submit</Bound.Button>
          </CardFooter>
        </Card>
      </Reform>

      <Card>
        <CardHeader>
          <CardTitle title="Result" />
          <CardBody>
            {!data ? (
              "Run Search"
            ) : (
              <ReactJson
                src={{ ...data.searchContentBlocks, contentBlocks: rootBlocks }}
              />
            )}
          </CardBody>
        </CardHeader>
      </Card>
    </div>
  );
};

interface HasChildren {
  children: ContentBlock[];
}

type ContentBlock =
  GetSearchContentBlocksQuery["searchContentBlocks"]["contentBlocks"][number];

const rebuildHierarchy = (contentBlocks: ContentBlock[]) => {
  const blocks: Array<ContentBlock & HasChildren> = contentBlocks.map((cb) => ({
    ...cb,
    children: [],
  }));
  const rootBlocks: (ContentBlock & HasChildren)[] = [];
  const blocksMap = Maps.from(blocks, (cb) => cb.id);

  for (const block of blocks) {
    const parent = blocksMap.get(block.parentId);

    if (parent) {
      parent.children.push(block);
    } else {
      rootBlocks.push(block);
    }
  }

  return rootBlocks;
};
