import { useSuspenseQuery } from "@apollo/client";
import React from "react";
import { graphql } from "../../../gql";
import { CoworkerQuestionCard } from "../../knowledge/components/CoworkersQuestionCard";

const coworkerQuestionsQuery = graphql(`
  query UnansweredQuestions {
    unansweredQuestions: coworkerQuestions(where: { isAnswered: false }) {
      ...CoworkerQuestion
    }
  }
`);

export const SearchIndexPage: React.FC = () => {
  const {
    data: { unansweredQuestions },
  } = useSuspenseQuery(coworkerQuestionsQuery, {
    fetchPolicy: "cache-and-network",
  });

  if (unansweredQuestions.length === 0) {
    return null;
  }

  return (
    <section>
      <h1 className="text-lg mb-3">Coworker Questions</h1>
      <ul className="grid grid-cols-3 gap-4">
        {unansweredQuestions.map((question) => (
          <li key={question.id}>
            <CoworkerQuestionCard question={question} />
          </li>
        ))}
      </ul>
    </section>
  );
};
