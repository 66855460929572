import { Outlet } from "react-router-dom";
import { PageHeader } from "../../components/Page";

export const ConnectionsRoot = () => {
  return (
    <>
      <PageHeader title="Connections" />
      <Outlet />
    </>
  );
};
