import { FaFileDownload } from "react-icons/fa";
import { FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa6";
import { SiGoogledocs } from "react-icons/si";
import { cn } from "../../../lib/utils";

export const mimetypeColor = (mimetype: string) => {
  if (mimetype === "application/pdf") {
    return "text-red-800";
  }
  if (isExcel(mimetype)) {
    return "text-green-600";
  }
  if (mimetype === "application/vnd.google-apps.document") {
    return "text-blue-700";
  }
  if (isWord(mimetype)) {
    return "text-blue-700";
  }

  return "text-primary";
};

export const SpaceFileIcon: React.FC<{
  file: { originalMimeType: string };
  className?: string;
}> = ({ file: { originalMimeType }, className }) => {
  const color = mimetypeColor(originalMimeType);
  const classname = cn(color, className);
  if (originalMimeType === "application/pdf") {
    return <FaFilePdf className={classname} />;
  }
  if (isExcel(originalMimeType)) {
    return <FaFileExcel className={classname} />;
  }
  if (originalMimeType === "application/vnd.google-apps.document") {
    return <SiGoogledocs className={classname} />;
  }
  if (isWord(originalMimeType)) {
    return <FaFileWord className={classname} />;
  }

  return <FaFileDownload className={classname} />;
};

const isWord = (mimeType: string) =>
  mimeType ===
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

const isExcel = (mimeType: string) =>
  mimeType === "application/vnd.ms-excel" ||
  mimeType ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
