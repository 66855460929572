import { useMutation, useSuspenseQuery } from "@apollo/client";
import { FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { PageContent, PageHeader } from "../../../components/Page";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { graphql } from "../../../gql";
import { MySpacesQuery } from "../../../gql/graphql";
import { CreateSpaceButton } from "../components/CreateSpaceButton";
import { SpaceIcon } from "../components/SpaceIcon";

export const SpaceListPage: React.FC = () => {
  return (
    <>
      <PageHeader title="Spaces">
        <CreateSpaceButton />
      </PageHeader>
      <PageContent>
        <SpaceList />
      </PageContent>
    </>
  );
};

const allSpaces = graphql(`
  query MySpaces {
    spaces {
      id
      name
      source
    }
  }
`);

const SpaceList: React.FC = () => {
  const {
    data: { spaces },
  } = useSuspenseQuery(allSpaces, {
    fetchPolicy: "network-only",
  });

  const hasSpaces = spaces.length > 0;

  return (
    <section className="bg-white p-2 rounded shadow">
      {hasSpaces ? (
        <ul>
          {spaces.map((space) => (
            <li key={space.id} className="flex items-center ">
              <Link
                to={`/spaces/${space.id}`}
                className="flex flex-1 items-center py-2 hover:bg-zinc-100 -mx-2 px-2 text-lg text-slate-800 transition-colors"
              >
                <SpaceIcon source={space.source} className="mr-3" />
                {space.name}
              </Link>
              <SpaceListControls space={space} />
            </li>
          ))}
        </ul>
      ) : (
        <p className="italic text-center">
          No Spaces found. Please create one to start searching!
        </p>
      )}
    </section>
  );
};

const deleteSpaceMutation = graphql(`
  mutation DeleteSpace($id: ID!) {
    deleteSpace(id: $id)
  }
`);

const SpaceListControls: React.FC<{
  space: MySpacesQuery["spaces"][number];
}> = ({ space }) => {
  const [deleteSpace] = useMutation(deleteSpaceMutation, {
    variables: { id: space.id },
    onError: () => toast.error("An error occurred"),
    refetchQueries: ["MySpaces"],
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="ghost">
          <FaEllipsisV />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => deleteSpace()}>
            Delete
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
