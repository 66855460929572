// const streams: Map<string, >

import { toast } from "sonner";
import { z } from "zod";
import { apolloClient } from "../../apollo";
import { SearchRecordChunkStatus, SearchRecordStatus } from "../../gql/graphql";
import { authFetchEventSource } from "../../utils/request.utils";

const seenRecordIds = new Set<string>();

const EventSchema = z.union([
  z.object({
    objectType: z.literal("SearchRecord"),
    objectId: z.string(),
    type: z.literal("StatusChanged"),
    status: z.string().transform((s) => s as SearchRecordStatus),
  }),
  z.object({
    objectType: z.literal("SearchRecord"),
    objectId: z.string(),
    type: z.literal("AnswerDelta"),
    delta: z.string(),
  }),
  z.object({
    objectType: z.literal("SearchRecordChunk"),
    objectId: z.string(),
    type: z.literal("StatusChanged"),
    status: z.string().transform((s) => s as SearchRecordChunkStatus),
  }),
  z.object({
    objectType: z.literal("SearchRecordChunk"),
    objectId: z.string(),
    type: z.literal("QuoteDelta"),
    delta: z.string(),
  }),
]);

// const streams = new Map<string, Promise<void>>();

export const triggerStream = (searchRecordId: string) => {
  if (seenRecordIds.has(searchRecordId)) {
    return;
  }
  seenRecordIds.add(searchRecordId);

  // const responsePromise = authFetch(
  //   `/api/search-record/${searchRecordId}/stream`
  // )
  //   .then(async (response) => {
  //     if (!(response.ok && response.body)) {
  //       toast.error("An error occurred");
  //       return;
  //     }

  //     const messageStream = response.body
  //       .pipeThrough(new TextDecoderStream())
  //       .pipeThrough(new LineSplitterTransformer())
  //       .pipeThrough(new SseTransformer());

  //     for await (const message of Streams.iter(messageStream)) {
  //       const event = EventSchema.parse(JSON.parse(message.data));

  //       if (event.type === "QuoteDelta") {
  //         apolloClient.cache.modify({
  //           id: `SearchRecordChunk:${event.objectId}`,
  //           fields: {
  //             quotes: (q: string) => q + event.delta,
  //           },
  //         });
  //       } else if (event.type === "AnswerDelta") {
  //         apolloClient.cache.modify({
  //           id: `SearchRecord:${event.objectId}`,
  //           fields: {
  //             aiAnswer: (q: string) => q + event.delta,
  //           },
  //         });
  //       } else if (event.type === "StatusChanged") {
  //         apolloClient.cache.modify({
  //           id: `${event.objectType}:${event.objectId}`,
  //           fields: {
  //             status: () => event.status,
  //           },
  //         });
  //       }
  //     }
  //   })
  //   .finally(() => {
  //     streams.delete(searchRecordId);
  //   });
  // streams.set(searchRecordId, responsePromise);

  authFetchEventSource(`/api/search-record/${searchRecordId}/stream`, {
    openWhenHidden: true,
    onmessage: (ev) => {
      if (ev.id == "") {
        return;
      }
      const event = EventSchema.parse(JSON.parse(ev.data));

      if (event.type === "QuoteDelta") {
        apolloClient.cache.modify({
          id: `SearchRecordChunk:${event.objectId}`,
          fields: {
            quotes: (q: string) => q + event.delta,
          },
        });
      } else if (event.type === "AnswerDelta") {
        apolloClient.cache.modify({
          id: `SearchRecord:${event.objectId}`,
          fields: {
            aiAnswer: (q: string) => q + event.delta,
          },
        });
      } else if (event.type === "StatusChanged") {
        apolloClient.cache.modify({
          id: `${event.objectType}:${event.objectId}`,
          fields: {
            status: () => event.status,
          },
        });
      }
    },
    onerror: (e) => {
      console.error(e);
      toast.error("An error occurred");
    },
  });
};

// class LineSplitterTransformer extends TransformStream<string, string> {
//   private buffer: string = "";

//   constructor() {
//     super({
//       transform: (chunk, controller) => {
//         this.buffer += chunk; // Append new chunk to buffer
//         const lines = this.buffer.split("\n");
//         this.buffer = lines.pop() ?? "";
//         for (const line of lines) {
//           controller.enqueue(line);
//         }
//       },
//       flush: (controller) => {
//         // At the end of the stream, flush any remaining data as a final line
//         if (this.buffer.length > 0) {
//           controller.enqueue(this.buffer);
//           this.buffer = "";
//         }
//       },
//     });
//   }
// }

// const MessageSchema = z.object({
//   data: z.string(),
// });
// type Message = z.infer<typeof MessageSchema>;

// class SseTransformer extends TransformStream<string, Message> {
//   constructor() {
//     super({
//       transform: (chunk, controller) => {
//         if (chunk.trim() === "") {
//           return;
//         }
//         controller.enqueue(MessageSchema.parse(JSON.parse(chunk)));
//       },
//     });
//   }
// }
