import { useMutation } from "@apollo/client";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { FaCheckSquare, FaEllipsisV } from "react-icons/fa";
import {
  FaCircleExclamation,
  FaFile,
  FaFolderOpen,
  FaPersonDigging,
  FaTriangleExclamation,
} from "react-icons/fa6";
import { toast } from "sonner";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "../../../components/ui/dropdown-menu";
import { graphql } from "../../../gql";
import {
  MinimalSpaceFileFragment,
  SpaceSource,
  SpaceStatus,
} from "../../../gql/graphql";
import { cn } from "../../../lib/utils";
import { SpaceFileLink } from "./SpaceFileLink";

interface Folder {
  id: string;
  name: string;
  content: Array<MinimalSpaceFileFragment | Folder>;
}
const isFolder = (f: MinimalSpaceFileFragment | Folder): f is Folder =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Array.isArray((f as any).content);

export const SpaceFileViewer: React.FC<{
  files: MinimalSpaceFileFragment[];
}> = ({ files }) => {
  const folder = convertToFolderStructure(files);

  return <FolderRender folder={folder} isRoot />;
};

const FolderRender: React.FC<{
  folder: Folder;
  isRoot?: boolean;
  depth?: number;
}> = ({ folder, isRoot = true, depth = 0 }) => {
  return (
    <div className="">
      {!isRoot && (
        <h2
          className="ml-4 flex items-center text-zinc-600 "
          style={{ paddingLeft: `${1 * (depth - 1)}rem` }}
        >
          <FaFolderOpen className="mr-1" /> {folder.name}
        </h2>
      )}
      <FileList content={folder.content} depth={depth} />
    </div>
  );
};

const FileList: React.FC<{
  content: Array<MinimalSpaceFileFragment | Folder>;
  depth: number;
}> = ({ content, depth }) => {
  const files = content.filter(
    (f): f is MinimalSpaceFileFragment => !isFolder(f)
  );
  const folders = content.filter(isFolder);
  return (
    <div>
      <ul>
        {files.map((file) => (
          <li
            className="flex even:bg-zinc-50"
            key={file.id}
            style={{ paddingLeft: `${1 * depth}rem` }}
          >
            <span
              className={cn(
                "p-1 px-2 text-xs rounded-full self-center ml-3 flex items-center gap-1",
                {
                  "text-amber-700 bg-amber-200":
                    file.status === SpaceStatus.Pending,
                  "text-blue-700 bg-blue-200":
                    file.status === SpaceStatus.Processing,
                  "text-green-700 bg-green-200":
                    file.status === SpaceStatus.Ready,
                  "text-red-700 bg-red-200": file.status === SpaceStatus.Error,
                }
              )}
            >
              {file.status === SpaceStatus.Pending && <FaTriangleExclamation />}
              {file.status === SpaceStatus.Processing && <FaPersonDigging />}
              {file.status === SpaceStatus.Ready && <FaCheckSquare />}
              {file.status === SpaceStatus.Error && <FaCircleExclamation />}
              {file.status}
            </span>
            <SpaceFileLink
              file={file}
              className="flex flex-1 items-center p-4 py-2 text-zinc-600 underline"
            >
              <FaFile className="mr-1" />
              {file.name}
            </SpaceFileLink>
            <div className="flex w-12 items-center py-2">
              <FileDropdown file={file} />
            </div>
          </li>
        ))}
      </ul>
      {folders.map((folder) => (
        <FolderRender
          folder={folder}
          key={folder.id}
          isRoot={false}
          depth={depth + 1}
        />
      ))}
    </div>
  );
};

const deleteSpaceFileMutation = graphql(`
  mutation DeleteSpaceFile($id: ID!) {
    deleteSpaceFile(id: $id)
  }
`);

const reprocessSpaceFileMutation = graphql(`
  mutation ReprocessSPaceFile($id: ID!) {
    reprocessSpaceFile(id: $id) {
      id
      status
    }
  }
`);

const reingestSpaceFileMutation = graphql(`
  mutation ReingestSpaceFile($id: ID!) {
    reingestSpaceFile(id: $id) {
      id
      status
    }
  }
`);
const FileDropdown: React.FC<{ file: MinimalSpaceFileFragment }> = ({
  file,
}) => {
  const [deleteSpaceFile] = useMutation(deleteSpaceFileMutation, {
    variables: {
      id: file.id,
    },
    onError: () => toast.error("An error occurred"),
    refetchQueries: ["SpaceDetails"],
  });

  const [reprocessSpaceFile] = useMutation(reprocessSpaceFileMutation, {
    variables: {
      id: file.id,
    },
    onError: () => toast.error("An error occurred"),
  });

  const [reingestSpaceFile] = useMutation(reingestSpaceFileMutation, {
    variables: {
      id: file.id,
    },
    onError: () => toast.error("An error occurred"),
  });
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="ghost">
          <FaEllipsisV />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          <button className="w-full">
            <DropdownMenuItem onClick={() => deleteSpaceFile()}>
              Delete
            </DropdownMenuItem>
          </button>

          <button className="w-full">
            <DropdownMenuItem onClick={() => reprocessSpaceFile()}>
              Reprocess
            </DropdownMenuItem>
          </button>

          {file.source === SpaceSource.MsSharepoint && (
            <button className="w-full">
              <DropdownMenuItem onClick={() => reingestSpaceFile()}>
                Reingest
              </DropdownMenuItem>
            </button>
          )}

          <DropdownMenuItem className="w-full">
            <a href={`/space-files/${file.id}/download`} target="_blank">
              Download File
            </a>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const convertToFolderStructure = (
  docRecords: MinimalSpaceFileFragment[]
): Folder => {
  const root: Folder = { id: "/", name: "/", content: [] };
  const idToContent = new Map<string, Folder>();
  idToContent.set(root.id, root);

  for (const doc of docRecords) {
    const pathParts = doc.path.split("/").filter((p) => p != "");

    let parent = root;

    for (let i = 0; i < pathParts.length; i++) {
      const id = "/" + pathParts.slice(0, i + 1).join("/");
      const name = pathParts[i];
      const isLast = i === pathParts.length - 1;

      if (isLast) {
        parent.content.push(doc);
      } else {
        const folder = idToContent.get(id) ?? {
          id,
          name,
          content: [],
        };

        if (!idToContent.has(id)) {
          parent.content.push(folder);
          idToContent.set(id, folder);
        }
        parent = folder;
      }
    }
  }

  return root;
};
