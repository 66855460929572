import { useController } from "react-hook-form";
import { Switch } from "../ui/switch";

export const BoundSwitch: React.FC<{
  name: string;
  disabled?: boolean;
  defaultValue?: boolean;
}> = ({ name, disabled, defaultValue }) => {
  const { field } = useController({ name, defaultValue });
  return (
    <Switch
      ref={field.ref}
      onBlur={field.onBlur}
      checked={field.value}
      onCheckedChange={field.onChange}
      disabled={disabled}
      aria-readonly={disabled}
    />
  );
};
