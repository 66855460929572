import { useSuspenseQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ErrorPage } from "./components/Errors";
import { PageLoader, Sidebar } from "./components/Page";
import { graphql } from "./gql";

export const App: React.FC = () => (
  <Sentry.ErrorBoundary fallback={<ErrorPage />}>
    <div className="w-screen min-h-screen flex">
      <Sidebar />
      <div className="flex flex-col w-full p-1 px-2">
        <Suspense fallback={<PageLoader />}>
          <IntercomManager />
          <Outlet />
        </Suspense>
      </div>
    </div>
  </Sentry.ErrorBoundary>
);

const loadMe = graphql(`
  query Me {
    me {
      id
      name
      createdAt
      email
      icHash
    }
  }
`);

declare global {
  interface Window {
    Intercom: (arg1: string, arg2?: unknown) => void;
  }
}
window.Intercom =
  window.Intercom ||
  (() => {
    null;
  });

const IntercomManager = () => {
  const {
    data: { me },
  } = useSuspenseQuery(loadMe);

  useEffect(() => {
    if (me) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "zuvzzjaf",
        user_id: me.id,
        name: me.name,
        email: me.email,
        created_at: me.createdAt,
        user_hash: me.icHash,
      });
    }
  }, [me]);

  const location = useLocation();
  useEffect(() => {
    window.Intercom("update");
  }, [location]);

  return null;
};
