import { useFragment, useLazyQuery, useSuspenseQuery } from "@apollo/client";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FaPaperPlane } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { Reform } from "../../../components/Reform";
import { Spinner } from "../../../components/Spinner";
import { Bound } from "../../../components/forms/boundComponents";
import { Button } from "../../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { graphql } from "../../../gql";
import { SearchBarRecordFragmentDoc } from "../../../gql/graphql";
import { useParamsStrict } from "../../../hooks/useParamsStrict";
import { cn } from "../../../lib/utils";

// const formSchema = z.object({
//   query: z.string(),
// });

const searchDocument = graphql(`
  query Search($query: SearchQueryInput!) {
    search(query: $query) {
      id
    }
  }
`);

const ParamSchema = z.object({
  searchRecordId: z.string().optional(),
});

const SearchFormSchema = z.object({
  prompt: z.string(),
  spaceId: z
    .string()
    .nullish()
    .transform((s) => (s?.trim() === "" ? null : s)),
});
export const SearchBar: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { searchRecordId } = useParamsStrict(ParamSchema);

  const record = useFragment({
    fragment: SearchBarRecordFragmentDoc,
    fragmentName: "SearchBarRecord",
    from: {
      __typename: "SearchRecord",
      id: searchRecordId,
    },
  });

  const navigate = useNavigate();
  const [search, { loading }] = useLazyQuery(searchDocument, {
    fetchPolicy: "network-only",
    onError: () => toast.error("An error occurred while searching."),
    onCompleted: (data) => navigate(`/search/${data.search.id}`),
  });

  const disabled = loading;

  return (
    <Reform
      schema={SearchFormSchema}
      defaultValue={{ prompt: record.data.question, spaceId: null }}
      onSubmit={async (query) => {
        await search({
          // Note: Variables must be here. Otherwise, it's a typeahead search
          variables: {
            query: {
              ...query,
              reranker: {
                useReranker: false,
              },
            },
          },
        });
      }}
      className={cn("flex gap-2 items-center", className)}
    >
      <div className="border-300 flex flex-1 overflow-hidden rounded-full border shadow bg-white">
        {/* <span className="flex items-center p-1">
          <FaSearch />
        </span> */}
        <QueryInput />

        <Bound.Button
          type="submit"
          disabled={disabled}
          variant="ghost"
          size="icon"
          component="button"
          className={cn(
            "flex items-center justify-center p-3 transition-colors",
            "text-primary hover:bg-primary/10",
            "disabled:bg-inherit disabled:text-slate-400"
          )}
        >
          {loading ? <Spinner /> : <FaPaperPlane />}
        </Bound.Button>
      </div>
      <SpaceSelect />
    </Reform>
  );
};

const QueryInput = () => {
  const { register } = useFormContext();
  return (
    <input
      {...register("prompt")}
      type="text"
      className="flex-1 p-4 py-3 outline-none border-none"
      autoComplete="off"
      autoFocus
    />
  );
};

const spacesQuery = graphql(`
  query SpacesForSearchBar {
    spaces {
      id
      name
    }
  }
`);

const SpaceSelect = () => {
  const { setValue } = useFormContext<z.infer<typeof SearchFormSchema>>();
  const currentSpaceId =
    useWatch<z.infer<typeof SearchFormSchema>>()["spaceId"] ?? "";

  // const [currentSpaceId, setCurrentSpaceId] = useState<string>();

  const {
    data: { spaces },
  } = useSuspenseQuery(spacesQuery, { fetchPolicy: "cache-and-network" });

  return (
    <div className="bg-white rounded-lg flex">
      <Select
        value={currentSpaceId}
        onValueChange={(spaceId) => setValue("spaceId", spaceId)}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="All Spaces" />
        </SelectTrigger>
        <SelectContent>
          {spaces.map((space) => (
            <SelectItem key={space.id} value={space.id}>
              {space.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button
        variant="ghost"
        size="icon"
        disabled={currentSpaceId === ""}
        type="button"
        onClick={() => setValue("spaceId", null)}
      >
        <FaCircleXmark />
      </Button>
    </div>
  );

  // return (
  //   <Popover open={open} onOpenChange={setOpen}>
  //     <PopoverTrigger asChild>
  //       <Button
  //         variant="outline"
  //         role="combobox"
  //         aria-expanded={open}
  //         className="w-[200px] justify-between"
  //       >
  //         {currentSpace?.name ?? "All Spaces"}
  //         <FaChevronUp className="ml-2 h-4 w-4 shrink-0 opacity-50" />
  //       </Button>
  //     </PopoverTrigger>
  //     <PopoverContent className="w-[200px] p-0">
  //       <Command>
  //         <CommandInput placeholder="Select Space" />
  //         <CommandEmpty>No Spaces found</CommandEmpty>
  //         <CommandGroup>
  //           {spaces.map((space) => (
  //             <CommandItem
  //               key={space.id}
  //               // value={space.id}
  //               // onSelect={(spaceId) => {
  //               //   setValue(
  //               //     "spaceId",
  //               //     spaceId === currentSpaceId ? undefined : spaceId
  //               //   );
  //               //   setOpen(false);
  //               // }}
  //             >
  //               {/* <FaCheck
  //                 className={cn(
  //                   "mr-2 h-4 w-4",
  //                   currentSpaceId === space.id ? "opacity-100" : "opacity-0"
  //                 )}
  //               /> */}
  //               {space.name}
  //             </CommandItem>
  //           ))}
  //         </CommandGroup>
  //       </Command>
  //     </PopoverContent>
  //   </Popover>
  // );
};
