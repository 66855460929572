import { useSuspenseQuery } from "@apollo/client";
import { first } from "lodash";
import { useState } from "react";
import { FaFileUpload } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import { Link, Outlet, useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { PageContent, PageHeader } from "../../../components/Page";
import { Spinner } from "../../../components/Spinner";
import { Button, buttonVariants } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { graphql } from "../../../gql";
import { SpaceSource } from "../../../gql/graphql";
import { cn } from "../../../lib/utils";
import { authFetch } from "../../../utils/request.utils";

export const spaceDetailsQuery = graphql(`
  query SpaceDetails($id: ID!) {
    space(id: $id) {
      id
      name
      sourceId
      source
      files {
        ...MinimalSpaceFile
      }
    }
  }
`);

const ParamSchema = z.object({
  spaceId: z.string(),
});

export const SpaceDetailRoot = () => {
  const params = ParamSchema.parse(useParams());
  const {
    data: { space },
    refetch,
  } = useSuspenseQuery(spaceDetailsQuery, {
    variables: {
      id: params.spaceId,
    },
    fetchPolicy: "cache-and-network",
  });
  return (
    <>
      <PageHeader title={`Spaces: ${space.name}`}>
        {space.source === SpaceSource.Manual && (
          <ManualSpaceMenu spaceId={space.id} refetch={refetch} />
        )}
      </PageHeader>
      <PageContent>
        <Outlet />
      </PageContent>
    </>
  );
};

const ManualSpaceMenu: React.FC<{ spaceId: string; refetch(): void }> = ({
  spaceId,
  refetch,
}) => {
  const [saving, setSaving] = useState(false);
  return (
    <label
      className={cn(
        buttonVariants(),
        saving && "bg-primary/80 hover:bg-primary/80 cursor-not-allowed"
      )}
    >
      {saving ? (
        <Spinner className="mr-1 text-white" />
      ) : (
        <FaFileUpload className="mr-1" />
      )}
      Upload
      <input
        type="file"
        disabled={saving}
        className="hidden"
        onChange={async (e) => {
          const file = first(e.target.files);
          if (!file) {
            toast("No file selected");
            return;
          }

          setSaving(true);
          const formData = new FormData();
          formData.set("file", file);

          try {
            const res = await authFetch(`/api/spaces/${spaceId}/files`, {
              method: "POST",
              body: formData,
            });
            if (!res.ok) {
              throw new Error();
            }
            refetch();
            setSaving(false);
            toast.success("File upload successful");
          } catch (e) {
            toast.error("File upload failed");
          }
        }}
      />
    </label>
  );
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">Add File</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem>
            <label
              htmlFor="file-upload-input"
              className="w-full h-full flex items-center"
            >
              <FaFileUpload className="mr-1" />
              Upload
            </label>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link
              className="w-full h-full flex items-center"
              to={`/spaces/${spaceId}/new-note`}
            >
              <FaPencil className="mr-1" />
              Write Note
            </Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
