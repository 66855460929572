import {
  FetchEventSourceInit,
  fetchEventSource,
} from "@microsoft/fetch-event-source";
import {
  SetURLSearchParams,
  URLSearchParamsInit,
  useSearchParams,
} from "react-router-dom";
import { ZodRawShape, z } from "zod";
import { Auth } from "./auth.utils";

export const useSearchParamsStrict = <TSchema extends z.ZodObject<ZodRawShape>>(
  schema: TSchema,
  defaultInit?: URLSearchParamsInit
): [z.infer<TSchema>, SetURLSearchParams] => {
  const [params, setParams] = useSearchParams(defaultInit);
  return [schema.parse(params), setParams];
};

export const getUrlParamParser =
  <TSchema extends z.ZodObject<ZodRawShape>>(schema: TSchema) =>
  (request: Request): z.infer<TSchema> => {
    const url = new URL(request.url);
    return schema.parse(Object.fromEntries(url.searchParams));
  };

export const authFetch = async (
  input: string | URL,
  init?: RequestInit & { headers?: Record<string, string> }
) =>
  fetch(`${import.meta.env.VITE_API_URL}${input}`, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${await Auth.getAccessToken()}`,
    },
  });

export const authFetchEventSource = async (
  input: string | URL,
  init: FetchEventSourceInit
) =>
  fetchEventSource(`${import.meta.env.VITE_API_URL}${input}`, {
    ...init,
    headers: {
      ...init.headers,
      Authorization: `Bearer ${await Auth.getAccessToken()}`,
    },
  });
