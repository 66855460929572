import { useSuspenseQuery } from "@apollo/client";
import { FaExclamationCircle, FaExclamationTriangle } from "react-icons/fa";
import { FaMagnifyingGlass, FaUpRightFromSquare } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { PageContent, PageHeader } from "../../../components/Page";
import { Button, buttonVariants } from "../../../components/ui/button";
import { Separator } from "../../../components/ui/separator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { graphql } from "../../../gql";
import { cn } from "../../../lib/utils";
import { Arrays } from "../../../utils/arrays.utils";

const dashboardQuery = graphql(`
  query ChurnDashboardQuery {
    npsTrailingYearCounts {
      year
      month
      low
      med
      high
    }
    problematicReps {
      id
      name
      nps
    }

    atRiskCompanies {
      id
      name
      nps
    }
  }
`);

function formatAvg(number: number, decimalPlaces = 1) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}

export const ChurnIndexPage = () => {
  const {
    data: { npsTrailingYearCounts, problematicReps, atRiskCompanies },
  } = useSuspenseQuery(dashboardQuery);
  const navigate = useNavigate();

  return (
    <>
      <PageHeader title="Dashboard">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(
              `/dashboards/search?q=${
                (e.currentTarget.querySelector("#search") as any).value
              }`
            );
          }}
          className="border border-zinc-400 rounded-full overflow-hidden p-0"
        >
          <input
            id="search"
            type="search"
            className="p-1 px-4 my-0 outline-none"
            // onChange={(e) => setSearch(e.target.value)}
          />
          <Button size="icon" variant="ghost" type="submit">
            <FaMagnifyingGlass />
          </Button>
        </form>
      </PageHeader>
      <PageContent>
        <div className="flex gap-4">
          <section className="gap-4 flex flex-col">
            <Card>
              <CardHeader>
                <CardTitle title="NPS" />
              </CardHeader>
              <CardBody className="flex">
                <div className="my-6 mt-4 flex flex-col items-center flex-1">
                  <h3 className="text-center text-lg flex items-center mb-4 -ml-1 relative">
                    <span className="text-rose-600 text-3xl align-middle absolute -left-4 -mt-1">
                      •{" "}
                    </span>
                    1-4
                  </h3>
                  <span className="text-4xl">
                    {npsTrailingYearCounts.reduce((a, b) => a + b.low, 0)}
                  </span>
                </div>

                <Separator orientation="vertical" className="h-auto" />

                <div className="my-6 mt-4 flex flex-col items-center flex-1">
                  <h3 className="text-center text-lg flex items-center mb-4 -ml-1 relative">
                    <span className="text-amber-600 text-3xl align-middle absolute -left-4 -mt-1">
                      •{" "}
                    </span>
                    5-7
                  </h3>
                  <span className="text-4xl">
                    {npsTrailingYearCounts.reduce((a, b) => a + b.med, 0)}
                  </span>
                </div>

                <Separator orientation="vertical" className="h-auto" />

                <div className="my-6 mt-4 flex flex-col items-center flex-1">
                  <h3 className="text-center text-lg flex items-center mb-4 -ml-1 relative">
                    <span className="text-emerald-600  text-3xl align-middle absolute -left-4 -mt-1">
                      •{" "}
                    </span>
                    8-10
                  </h3>
                  <span className="text-4xl">
                    {npsTrailingYearCounts.reduce((a, b) => a + b.high, 0)}
                  </span>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle title="MoM NPS" />
              </CardHeader>
              <CardBody className="px-4">
                <AreaChart
                  width={700}
                  height={400}
                  data={npsTrailingYearCounts}
                >
                  <Legend
                    verticalAlign="top"
                    height={36}
                    formatter={(val) => {
                      return <span className="text-slate-600 mr-2">{val}</span>;
                    }}
                    iconType="circle"
                    iconSize={6}
                  />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <Tooltip />
                  <XAxis dataKey={(r) => `${r.year}/${r.month}`} />
                  <YAxis />
                  {/* <Line type="monotone" dataKey="total" /> */}
                  <Area
                    stackId="1"
                    type="monotone"
                    dataKey="low"
                    stroke="rgb(225, 29, 72)"
                    fill="rgba(225, 29, 72, 0.6)"
                  />
                  <Area
                    stackId="1"
                    type="monotone"
                    dataKey="med"
                    stroke="rgb(251,146,60)"
                    fill="rgba(251,146,60,0.6)"
                  />
                  <Area
                    stackId="1"
                    type="monotone"
                    dataKey="high"
                    stroke="rgb(5,150,105)"
                    fill="rgba(5,150,105,0.6)"
                  />
                </AreaChart>
              </CardBody>
            </Card>
          </section>

          <section className="gap-4 flex flex-col flex-1">
            <Card>
              <CardHeader>
                <CardTitle
                  title="Reps to follow up with"
                  subtitle="These reps have more negative interactions than normal"
                  icon={<FaExclamationTriangle className="text-orange-600" />}
                />
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Rep</TableHead>
                      <TableHead>NPS</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {problematicReps.map((rep) => (
                      <TableRow key={rep.id}>
                        <TableCell>{rep.name}</TableCell>
                        <TableCell>{formatAvg(rep.nps)}</TableCell>
                        <TableCell>
                          <Link
                            to={`./employee/${rep.id}`}
                            className={cn(
                              buttonVariants({ variant: "link" }),
                              "flex gap-2 items-center"
                            )}
                          >
                            View Details
                            <FaUpRightFromSquare />
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle
                  title="Churn Risk"
                  subtitle="Companies with a high risk for churn"
                  icon={<FaExclamationCircle className="text-red-600" />}
                />
              </CardHeader>
              <CardBody>
                <p className="text-zinc-600 text-sm text-center">
                  There are currently{" "}
                  <span className="text-base text-red-600">
                    {atRiskCompanies.length}
                  </span>{" "}
                  companies at risk for churn.{" "}
                  <Link
                    to="./at-risk"
                    className={cn(buttonVariants({ variant: "link" }), "px-0")}
                  >
                    See them all →
                  </Link>
                </p>

                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Rep</TableHead>
                      <TableHead>NPS</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {Arrays.sort(atRiskCompanies, (a, b) => a.nps - b.nps).map(
                      (company) => (
                        <TableRow key={company.id}>
                          <TableCell>{company.name}</TableCell>
                          <TableCell>{formatAvg(company.nps)}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </section>
        </div>
      </PageContent>
    </>
  );
};
