import { RouteObject } from "react-router-dom";
import { ConnectionsRoot } from "./ConnectionsRoot";
import { ConnectionsIndex } from "./pages/ConnectionsIndex";

export const connectorRoutes: RouteObject[] = [
  {
    element: <ConnectionsRoot />,
    children: [
      {
        index: true,
        element: <ConnectionsIndex />,
      },
    ],
  },
];
