import { useFormContext } from "react-hook-form";
import { cn } from "../../lib/utils";
import { getIdFromName } from "./form.utils";

export const BoundLabel: React.FC<
  React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > & { name: string }
> = ({ name, className, children, ...props }) => {
  const context = useFormContext();
  const fieldErrors = context.formState.errors[name];

  return (
    <label
      htmlFor={getIdFromName(name)}
      {...props}
      className={cn(
        {
          "text-red-700": fieldErrors,
        },
        "block mb-1 font-light text-sm text-zinc-800",
        className
      )}
    >
      {children}
    </label>
  );
};
