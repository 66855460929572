import { PropsWithChildren } from "react";
import { cn } from "../lib/utils";

type BaseProps = PropsWithChildren<{ className?: string }>;
export const Card: React.FC<BaseProps> = ({ className, children }) => (
  <article
    className={cn("bg-white shadow rounded relative flex flex-col", className)}
  >
    {children}
  </article>
);

export const CardHeader: React.FC<BaseProps & { sticky?: boolean }> = ({
  className,
  children,
  sticky = false,
}) => (
  <header
    className={cn(
      "p-2 border-b border-zinc-100 bg-white/70 backdrop-blur rounded items-center",
      { "sticky top-0": sticky },
      className
    )}
  >
    {children}
  </header>
);

export const CardTitle: React.FC<{
  icon?: React.ReactNode;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  className?: string;
}> = ({ className, title, icon, subtitle }) => (
  <div
    className={cn(
      "flex items-center text-zinc-700 font-semibold text-sm",
      className
    )}
  >
    {icon && <div className="mr-1 flex mb-auto mt-1">{icon}</div>}
    <div className="flex-1">
      <h2>{title}</h2>
      {subtitle && (
        <h3 className="text-xs font-normal text-zinc-500">{subtitle}</h3>
      )}
    </div>
  </div>
);

export const CardBody: React.FC<BaseProps> = ({ className, children }) => (
  <div className={cn("p-2 flex-1", className)}>{children}</div>
);

export const CardFooter: React.FC<BaseProps> = ({ className, children }) => (
  <footer className={cn("p-2 pt-1", className)}>{children}</footer>
);
