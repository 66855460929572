import { Auth } from "./auth.utils";

const API_URL: string = import.meta.env.VITE_API_URL;
export const apiUrl = (path: string) => new URL(path, API_URL).href;

const authedRedirect = async (path: string, redirectUrl: string) => {
  const url = apiUrl(path);

  postRedirect(url, {
    accessToken: await Auth.getAccessToken(),
    redirectUrl,
  });
};

export const apiRememberMe = async (redirectUrl: string) =>
  authedRedirect("/oauth/remember-me", redirectUrl);

function postRedirect(url: string, data: Record<string, string>) {
  // Create a form element
  const form = document.createElement("form");
  form.method = "POST";
  form.action = url;

  // Add data as hidden form fields
  for (const key in data) {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = key;
    hiddenField.value = data[key];
    form.appendChild(hiddenField);
  }

  // Append the form to the body and submit it
  document.body.appendChild(form);
  form.submit();
}
