import { Outlet } from "react-router-dom";
import {
  PageContent,
  PageHeader,
  PageHeaderLink,
  PageHeaderNav,
} from "../../components/Page";

export const KnowledgeRoot: React.FC = () => (
  <>
    <PageHeader title="Knowledge Base">
      <PageHeaderNav>
        <PageHeaderLink to="/knowledge/questions">
          Questions Asked
        </PageHeaderLink>
        <PageHeaderLink to="/knowledge/search">Searches</PageHeaderLink>
      </PageHeaderNav>
    </PageHeader>
    <PageContent>
      <Outlet />
    </PageContent>
  </>
);
