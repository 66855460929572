import { useMutation } from "@apollo/client";
import { FaLink } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Spinner } from "../../../components/Spinner";
import { Button } from "../../../components/ui/button";
import { graphql } from "../../../gql";
import { IntegrationFragment } from "../../../gql/graphql";

const createConnectionMutation = graphql(`
  mutation CreateConnection($integrationId: IntegrationKey!) {
    createConnection(integrationId: $integrationId) {
      id
      integrationKey
      isAuthorized
      integration {
        id
        connection {
          id
        }
      }
    }
  }
`);

export const CreateConnectionButton: React.FC<{
  integration: IntegrationFragment;
}> = ({ integration }) => {
  const navigate = useNavigate();
  const [createConnection, { loading }] = useMutation(
    createConnectionMutation,
    {
      variables: { integrationId: integration.id },
      onError: () => toast.error("An error occurred"),
      onCompleted: ({ createConnection: connection }) => {
        if (connection.isAuthorized) {
          toast.success(<>{integration.name} connected</>);
        } else {
          navigate(
            `/oauth/authorize/${integration.provider}?scope=${integration.requiredScope}`
          );
        }
      },
    }
  );
  return (
    <Button
      variant="outline"
      disabled={loading}
      onClick={() => {
        createConnection();
      }}
    >
      Connect {loading ? <Spinner /> : <FaLink className="ml-1" />}
    </Button>
  );
};
