import { useMutation, useSuspenseQuery } from "@apollo/client";
import { toast } from "sonner";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { PageContent, PageHeader } from "../../../components/Page";
import { Spinner } from "../../../components/Spinner";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../components/ui/alert";
import { Button } from "../../../components/ui/button";
import { graphql } from "../../../gql";

const organizationQuery = graphql(`
  query MyOrganization {
    myOrganization {
      id
      name
    }
  }
`);

const reindexMutation = graphql(`
  mutation ReindexOrg {
    reindexMyOrganization
  }
`);

export const AdminIndex = () => {
  const {
    data: { myOrganization: organization },
  } = useSuspenseQuery(organizationQuery);

  const [reindex, { loading: reindexing }] = useMutation(reindexMutation, {
    onCompleted: () => toast.success("Reindexing"),
    onError: () => toast.error("An error occured"),
  });

  return (
    <>
      <PageHeader title={`${organization.name} Settings`}></PageHeader>
      <PageContent className="gap-4 flex flex-col">
        <Alert variant="destructive" className="bg-red-100">
          <AlertTitle>Danger Zone</AlertTitle>
          <AlertDescription>
            Actions here may impact your organization's files or experience
          </AlertDescription>
        </Alert>

        <div className="gap-4 flex flex-wrap">
          <Card>
            <CardHeader>
              <CardTitle title="Reindex Organization" />
            </CardHeader>
            <CardBody className="flex flex-col">
              <Button
                variant="destructive"
                onClick={() => reindex()}
                disabled={reindexing}
              >
                Reindex {reindexing && <Spinner />}
              </Button>
              <p className="max-w-44 text-xs">
                This may prevent users from finding documents in their search
                for a while.
              </p>
            </CardBody>
          </Card>
        </div>
      </PageContent>
    </>
  );
};
