import { useLazyQuery } from "@apollo/client";
import ReactJson from "react-json-view";
import { z } from "zod";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Reform } from "../../../components/Reform";
import { Bound } from "../../../components/forms/boundComponents";
import { graphql } from "../../../gql";

const getSearchQueryQuery = graphql(`
  query GetSearchQuery($prompt: String!) {
    searchQuery(prompt: $prompt) {
      namedEntities
      phrases
    }
  }
`);

const paramSchema = z.object({
  prompt: z.string().optional(),
});
const formSchema = z.object({
  prompt: z.string(),
});

export const SearchQueryAdmin: React.FC = () => {
  // const [params, setParams] = useSearchParamsStrict(paramSchema)
  const [getQuery, { loading, data }] = useLazyQuery(getSearchQueryQuery, {
    fetchPolicy: "network-only",
  });
  return (
    <div className="flex flex-col gap-4">
      <Reform
        schema={formSchema}
        onSubmit={({ prompt }) => getQuery({ variables: { prompt } })}
      >
        <Card>
          <CardBody>
            <Bound.Label name="prompt">Prompt</Bound.Label>
            <Bound.Textarea name="prompt" required />
          </CardBody>
          <CardFooter>
            <Bound.Button disabled={loading}>Submit</Bound.Button>
          </CardFooter>
        </Card>
      </Reform>

      <Card>
        <CardHeader>
          <CardTitle title="Result" />
          <CardBody>
            {!data ? "Run Search" : <ReactJson src={data.searchQuery} />}
          </CardBody>
        </CardHeader>
      </Card>
    </div>
  );
};
