function from<K, V>(iterable: Iterable<V>, keyBy: (v: V) => K): Map<K, V>;
function from<K, V, V2>(
  iterable: Iterable<V>,
  keyBy: (v: V) => K,
  transform: (v: V) => V2
): Map<K, V2>;
function from<K, V, V2>(
  iterable: Iterable<V>,
  keyBy: (v: V) => K,
  transform?: (v: V) => V2
) {
  const map = new Map<K, V | V2>();
  for (const v of iterable) {
    map.set(keyBy(v), transform ? transform(v) : v);
  }
  return map;
}

export const Maps = {
  from,
};
