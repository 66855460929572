import { useMutation, useSuspenseQuery } from "@apollo/client";
import { FaExclamationCircle } from "react-icons/fa";
import {
  FaCirclePlus,
  FaCircleXmark,
  FaUpRightFromSquare,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Reform } from "../../../components/Reform";
import { Bound } from "../../../components/forms/boundComponents";
import { buttonVariants } from "../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { graphql } from "../../../gql";
import { cn } from "../../../lib/utils";
import { Arrays } from "../../../utils/arrays.utils";

const pageQuery = graphql(`
  query AtRiskPage {
    atRiskCompanies {
      id
      name
      nps
      alerts {
        id
        name
      }
    }
  }
`);

const createAlertMutation = graphql(`
  mutation CreateAlert($input: CreateAlertInput!) {
    createAlert(input: $input)
  }
`);

const deleteAlertMutation = graphql(`
  mutation DeleteAlert($id: ID!) {
    deleteAlert(id: $id) {
      id
      name
      alerts {
        id
        name
      }
    }
  }
`);
function formatAvg(number: number, decimalPlaces = 1) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}

export const AtRiskPage = () => {
  const [createAlert] = useMutation(createAlertMutation, {
    onError: () => toast.error("An error occurred"),
  });
  const [deleteAlert] = useMutation(deleteAlertMutation, {
    onCompleted: () => refetch(),
    onError: () => toast.error("An error occurred"),
  });

  const {
    data: { atRiskCompanies },
    refetch,
  } = useSuspenseQuery(pageQuery);
  return (
    <section className="gap-4 flex flex-col flex-1">
      <Reform
        schema={z.object({
          name: z.string(),
          phrase: z.string(),
          maxNps: z.coerce.number(),
        })}
        onSubmit={async (input) => {
          await createAlert({ variables: { input } });
          await refetch();
        }}
        className="flex gap-2"
      >
        <Card className="w-full">
          <CardHeader className="flex justify-between">
            <CardTitle title="Create Alert" />
            <Bound.Button size="sm">
              Create Alert <FaCirclePlus className="ml-1" />
            </Bound.Button>
          </CardHeader>
          <CardBody className="flex gap-4">
            <div className="flex flex-col flex-1">
              <Bound.Label name="name">Name</Bound.Label>
              <Bound.Input name="name" />
            </div>

            <div className="flex flex-col flex-1">
              <Bound.Label name="phrase">Phrase</Bound.Label>
              <Bound.Input name="phrase" />
            </div>
            <div className="flex flex-col flex-1">
              <Bound.Label name="maxNps">Max NPS Score</Bound.Label>
              <Bound.Input
                type="number"
                min={0}
                max={10}
                step={0.1}
                name="maxNps"
              />
            </div>
          </CardBody>
        </Card>
      </Reform>
      <Card>
        <CardHeader>
          <CardTitle
            title="Churn Risk"
            subtitle="Companies with a high risk for churn"
            icon={<FaExclamationCircle className="text-red-600" />}
          />
        </CardHeader>
        <CardBody>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Company</TableHead>
                <TableHead>NPS</TableHead>
                <TableHead>Risk Factors</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Arrays.sort(atRiskCompanies, (a, b) => a.nps - b.nps).map(
                (company) => (
                  <TableRow key={company.id}>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{formatAvg(company.nps)}</TableCell>
                    <TableCell className="flex gap-2">
                      {company.alerts.map((alert) => (
                        <span
                          key={alert.id}
                          className="rounded-full p-1 px-2 flex items-center gap-1 bg-violet-200 border border-violet-400 text-violet-800 font-semibold text-xs"
                        >
                          {alert.name}
                          <button
                            onClick={() =>
                              deleteAlert({ variables: { id: alert.id } })
                            }
                          >
                            <FaCircleXmark />
                          </button>
                        </span>
                      ))}
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`../${company.id}`}
                        className={cn(
                          buttonVariants({ variant: "link" }),
                          "flex gap-2 items-center"
                        )}
                      >
                        View Details
                        <FaUpRightFromSquare />
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </section>
  );
};
