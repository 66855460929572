import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { Card, CardBody, CardFooter } from "../../../components/Card";
import { PageContent, PageHeader } from "../../../components/Page";
import { Reform } from "../../../components/Reform";
import { BoundButton } from "../../../components/forms/BoundButton";
import { Bound } from "../../../components/forms/boundComponents";
import { graphql } from "../../../gql";

export const NewManualSpacePage: React.FC = () => {
  return (
    <>
      <PageHeader title="Spaces" subtitle="New Drive" />
      <PageContent>
        <NewSpaceForm />
      </PageContent>
    </>
  );
};

const newSpaceFormSchema = z.object({
  name: z.string().min(1, "Must have a name"),
});

const upsertManualSpace = graphql(`
  mutation UpsertManualSpace($name: String!) {
    upsertManualSpace(input: { name: $name }) {
      id
    }
  }
`);

const NewSpaceForm: React.FC = () => {
  const navigate = useNavigate();
  const [upsertSpace] = useMutation(upsertManualSpace, {
    onCompleted: ({ upsertManualSpace }) =>
      navigate(`/spaces/${upsertManualSpace.id}`),
    onError: () => toast.error("An error occurred"),
  });
  return (
    <Reform
      schema={newSpaceFormSchema}
      onSubmit={({ name }) => upsertSpace({ variables: { name } })}
    >
      <Card>
        <CardBody>
          <Bound.Label name="name">Name</Bound.Label>
          <Bound.Input name="name" />
        </CardBody>
        <CardFooter className="flex justify-end">
          <BoundButton>Save</BoundButton>
        </CardFooter>
      </Card>
    </Reform>
  );
};
