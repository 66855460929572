import { Outlet } from "react-router-dom";
import {
  PageContent,
  PageHeader,
  PageHeaderLink,
  PageHeaderNav,
} from "../../../components/Page";

export const SearchAdminRoot = () => (
  <>
    <PageHeader title="Search Admin">
      <PageHeaderNav>
        <PageHeaderLink to="./query">Search Query</PageHeaderLink>
        <PageHeaderLink to="./documents">Documents</PageHeaderLink>
        <PageHeaderLink to="./content-cells">Content Cells</PageHeaderLink>
        <PageHeaderLink to="./content-blocks">Content Blocks</PageHeaderLink>
        <PageHeaderLink to="./search-record">Search Record</PageHeaderLink>
      </PageHeaderNav>
    </PageHeader>
    <PageContent>
      <Outlet />
    </PageContent>
  </>
);
