/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useFormContext } from "react-hook-form";
import { getIdFromName } from "./form.utils";

export function registerFormInput<TProps>(
  component: React.ComponentType<TProps>
) {
  const BoundComponent: React.FC<TProps & { name: string }> = ({
    name,
    ...props
  }) => {
    const { register } = useFormContext();
    const Component = component as any;
    return (
      <Component id={getIdFromName(name)} {...props} {...register(name)} />
    );
  };

  BoundComponent.displayName = `Registered(${
    (component as any).displayName ?? (component as any).name
  })`;
  return BoundComponent;
}
