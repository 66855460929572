import { useSuspenseQuery } from "@apollo/client";
import { FaQuestionCircle } from "react-icons/fa";
import { FaRobot } from "react-icons/fa6";
import ReactMarkdown from "react-markdown";
import { z } from "zod";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { graphql } from "../../../gql";
import { useParamsStrict } from "../../../hooks/useParamsStrict";
import { SpaceFileStylizedLink } from "../../spaces/components/SpaceFileLink";

const searchRecordQuery = graphql(`
  query SearchRecordDetails($id: ID!) {
    searchRecord(id: $id) {
      id
      createdAt
      question
      aiAnswer
      user {
        id
        name
        email
      }
      chunks {
        id
        markdown
        cells {
          id
          text
        }
        spaceFile {
          ...MinimalSpaceFile
        }
      }
    }
  }
`);

const paramSchema = z.object({
  searchRecordId: z.string(),
});
export const SearchDetailsPage: React.FC = () => {
  const { searchRecordId } = useParamsStrict(paramSchema);
  const { data } = useSuspenseQuery(searchRecordQuery, {
    variables: { id: searchRecordId },
  });

  const { searchRecord } = data;
  return (
    <div className="flex flex-wrap gap-4">
      <Card className="w-full">
        <CardHeader>
          <CardTitle icon={<FaQuestionCircle />} title="Question" />
        </CardHeader>
        <CardBody>{searchRecord.question}</CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle icon={<FaRobot />} title="AI Answer" />
        </CardHeader>
        <CardBody>
          <ReactMarkdown className="prose">
            {searchRecord.aiAnswer}
          </ReactMarkdown>
        </CardBody>
      </Card>

      {/* <Card key={chunk.id}></Card> */}
      {searchRecord.chunks.map((chunk) => (
        <div className="flex gap-4" key={chunk.id}>
          <Card className="flex-1">
            <CardHeader className="flex justify-between items-center">
              <CardTitle title={chunk.spaceFile.name} />
              <SpaceFileStylizedLink file={chunk.spaceFile}>
                Download
              </SpaceFileStylizedLink>
            </CardHeader>
            <CardBody>
              <ReactMarkdown className="prose">{chunk.markdown}</ReactMarkdown>
            </CardBody>
          </Card>

          <Card className="flex-1">
            <CardBody>
              <ul className="list-disc text-sm flex flex-col gap-1 ml-4">
                {chunk.cells.map((cell) => (
                  <li key={cell.id}>{cell.text}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </div>
      ))}
    </div>
  );
};
