import { createClient } from "@propelauth/javascript";
import { LoaderFunction } from "react-router-dom";

const authClient = createClient({
  authUrl: import.meta.env.VITE_APP_AUTH_URL ?? "",
  enableBackgroundTokenRefresh: true,
});

export const authLoader: LoaderFunction = async () => {
  const authInfo = await authClient.getAuthenticationInfoOrNull();
  if (!authInfo) {
    window.location.href = authClient.getLoginPageUrl();
  }
  return null;
};

export const Auth = {
  client: authClient,
  getAccessToken: async () => {
    const authInfo = await authClient.getAuthenticationInfoOrNull();

    if (!authInfo) {
      throw new Error("Auth info not present");
    }

    return authInfo.accessToken;
  },
};
