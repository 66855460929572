import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "sonner";
import "./index.css";

import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo.ts";
import { AuthProvider } from "./components/Auth.tsx";
import { rootRoutes } from "./rootRoutes.tsx";

import * as Sentry from "@sentry/react";
import { Auth } from "./utils/auth.utils.ts";

Sentry.init({
  dsn: "https://b7cbf220a726943034deb81d1601c775@o4505885411835906.ingest.us.sentry.io/4506944755990528",
  enabled: !import.meta.env.DEV,
  // tunnel: `${import.meta.env.VITE_API_URL}/monitoring/sentry`,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api.tadatoday\.ai\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Auth.client.addLoggedInChangeObserver((loggedIn) => {
  if (loggedIn) {
    // const user = Auth.client.
    // Sentry.setUser({ id})
  } else {
    Sentry.setUser(null);
  }
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <Toaster richColors />
      <AuthProvider>
        <RouterProvider router={rootRoutes} />
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);
