import { createBrowserRouter, redirect } from "react-router-dom";
import { App } from "./App";
import { adminRoutes } from "./modules/admin/adminRoutes";
import { churnRoutes } from "./modules/churn/churnRoutes";
import { connectorRoutes } from "./modules/connections/connectionRoutes";
import { knowledgeRoutes } from "./modules/knowledge/knowledgeRoutes";
import { oauthRoutes } from "./modules/oauth/oauthRoutes";
import { organizationRoutes } from "./modules/organization/organizationRoutes";
import { searchRoutes } from "./modules/search/searchRoutes";
import { spaceRoutes } from "./modules/spaces/spaceRoutes";
import { apiRememberMe } from "./utils/api.utils";

export const rootRoutes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, loader: () => redirect("/search") },
      { path: "admin", children: adminRoutes },
      { path: "search", children: searchRoutes },
      { path: "spaces", children: spaceRoutes },
      { path: "connections", children: connectorRoutes },
      { path: "oauth", children: oauthRoutes },
      { path: "knowledge", children: knowledgeRoutes },
      { path: "organization", children: organizationRoutes },
      { path: "dashboards", children: churnRoutes },
      {
        path: "space-files/:spaceFileId/download",
        loader: ({ params }) => {
          apiRememberMe(`/api/space-files/${params.spaceFileId}/download`);
          return null;
        },
      },
    ],
  },
]);
