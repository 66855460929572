import { format, parseISO } from "date-fns";

export const formatDateFromString = (dateString: string) =>
  formatDate(parseISO(dateString));
export const formatDate = (date: Date) => format(date, "MMM do, yyyy");

export const formatDateTimeFromString = (dateString: string) =>
  formatDateTime(parseISO(dateString));
export const formatDateTime = (date: Date) =>
  format(date, "MMM do, yyyy @ hh:mm aaa");
