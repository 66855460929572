import { useSuspenseQuery } from "@apollo/client";
import { graphql } from "../../../gql";
import { CoworkerQuestionCard } from "../components/CoworkersQuestionCard";

const coworkerQuestionsQuery = graphql(`
  query CoworkerQuestions {
    answered: coworkerQuestions(where: { isAnswered: true }) {
      ...CoworkerQuestion
    }
    unanswered: coworkerQuestions(where: { isAnswered: false }) {
      ...CoworkerQuestion
    }
  }
`);

export const CoworkerQuestionsPage: React.FC = () => {
  const { data: questions } = useSuspenseQuery(coworkerQuestionsQuery, {
    fetchPolicy: "network-only",
  });

  return (
    <>
      <h1 className="mb-2 mt-3">Unanswered</h1>
      <div className="grid gap-4 grid-cols-3">
        {questions.unanswered.map((question) => (
          <CoworkerQuestionCard question={question} />
        ))}
      </div>

      <h1 className="mb-2 mt-4">Answered</h1>
      <div className="grid gap-4 grid-cols-3">
        {questions.answered.map((question) => (
          <CoworkerQuestionCard question={question} />
        ))}
      </div>
    </>
  );
};
