import { RouteObject, redirect } from "react-router-dom";
import { AdminIndex } from "./pages/AdminIndex";
import { SearchAdminRoot } from "./pages/SearchAdminIndex";
import { SearchContentCellAdmin } from "./pages/SearchCellsAdmin";
import { SearchContentBlockAdmin } from "./pages/SearchContentBlockAdmin";
import { SearchDocumentsAdmin } from "./pages/SearchDocumentsAdmin";
import { SearchQueryAdmin } from "./pages/SearchQueryAdmin";
import { SearchRecordAdmin } from "./pages/SearchRecordAdmin";

export const adminRoutes: RouteObject[] = [
  {
    index: true,
    element: <AdminIndex />,
  },
  {
    path: "search",
    element: <SearchAdminRoot />,
    children: [
      { index: true, loader: () => redirect("./query") },
      { path: "query", element: <SearchQueryAdmin /> },
      { path: "documents", element: <SearchDocumentsAdmin /> },
      { path: "content-cells", element: <SearchContentCellAdmin /> },
      { path: "content-blocks", element: <SearchContentBlockAdmin /> },
      { path: "search-record", element: <SearchRecordAdmin /> },
    ],
  },
];
