import { useMutation } from "@apollo/client";
import { FaEllipsisV } from "react-icons/fa";
import { FaPaperPlane, FaUser } from "react-icons/fa6";
import { toast } from "sonner";
import { z } from "zod";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Reform } from "../../../components/Reform";
import { BoundButton } from "../../../components/forms/BoundButton";
import { Bound } from "../../../components/forms/boundComponents";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { graphql } from "../../../gql";
import { CoworkerQuestionFragment } from "../../../gql/graphql";

import { format } from "date-fns";
import { useState } from "react";

const answerCoworkerQuestionMutation = graphql(`
  mutation AnswerCoworkerQuestion($id: ID!, $answer: String!) {
    answerCoworkerQuestion(id: $id, answer: $answer) {
      ...CoworkerQuestion
    }
  }
`);

const coworkerQuestionSchema = z.object({
  answer: z.string().min(1),
});
export const CoworkerQuestionCard: React.FC<{
  question: CoworkerQuestionFragment;
  hideControls?: boolean;
}> = ({ question, hideControls = false }) => {
  let body: React.ReactNode;

  const [answerQuestion] = useMutation(answerCoworkerQuestionMutation, {
    onError: () => toast.error("An error occurred"),
    onCompleted: () => {
      setEditing(false);
      toast.success("Answer saved");
    },
    refetchQueries: ["CoworkerQuestions"],
  });
  const [editing, setEditing] = useState(!question.answer);

  if (editing) {
    body = (
      <Bound.Textarea
        name="answer"
        placeholder="Help answer a question your coworker asked!"
        defaultValue={question.answer ?? ""}
      />
    );
  } else {
    body = <p>{question.answer}</p>;
  }
  const footer = [];
  if (question.answeredBy) {
    footer.push(
      <div className="text-xs text-zinc-700 text-right">
        Answered by{" "}
        <strong>
          {question.answeredBy?.name ?? question.answeredBy?.email}
        </strong>{" "}
        on <strong>{format(question.answeredAt, "MMM do, yyyy")}</strong>
      </div>
    );
  }
  if (editing) {
    footer.push(
      <BoundButton icon={<FaPaperPlane />} iconAtEnd>
        Answer
      </BoundButton>
    );
  }

  // if (question.answer) {
  //   body = (
  //     <>
  //       <CardBody>
  //         <p>{question.answer}</p>
  //       </CardBody>
  //       <CardFooter>
  //         <div className="text-xs text-zinc-700 text-right">
  //           Answered by{" "}
  //           <strong>
  //             {question.answeredBy?.name ?? question.answeredBy?.email}
  //           </strong>{" "}
  //           on <strong>{format(question.answeredAt, "MMM do, yyyy")}</strong>
  //         </div>
  //       </CardFooter>
  //     </>
  //   );
  // } else {
  //   body = <AnswerQuestionForm question={question} />;
  // }

  return (
    <Card>
      <CardHeader className="flex justify-between">
        <CardTitle
          icon={<FaUser />}
          title={question.question}
          subtitle={`Asked by ${
            question.askedBy.name ?? question.askedBy?.email
          }`}
        />
        {!hideControls && (
          <Controls question={question} onPressEdit={() => setEditing(true)} />
        )}
      </CardHeader>
      <Reform
        schema={coworkerQuestionSchema}
        onSubmit={({ answer }) =>
          answerQuestion({ variables: { answer, id: question.id } })
        }
      >
        <CardBody>{body}</CardBody>
        {footer.length > 0 && <CardFooter>{footer}</CardFooter>}
      </Reform>
    </Card>
  );
};

const deleteCoworkerQuestionMutation = graphql(`
  mutation DeleteCoworkerQuestion($id: ID!) {
    deleteCoworkerQuestion(id: $id)
  }
`);

const Controls: React.FC<{
  question: CoworkerQuestionFragment;
  onPressEdit(): void;
}> = ({ question, onPressEdit }) => {
  const [deleteCoworkerQuestion] = useMutation(deleteCoworkerQuestionMutation, {
    variables: {
      id: question.id,
    },
    onError: () => toast.error("An error occurred"),
    refetchQueries: ["CoworkerQuestions"],
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="ghost">
          <FaEllipsisV />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          <button className="w-full">
            <DropdownMenuItem onClick={() => deleteCoworkerQuestion()}>
              Delete
            </DropdownMenuItem>
          </button>

          <button className="w-full">
            <DropdownMenuItem onClick={() => onPressEdit()}>
              Edit
            </DropdownMenuItem>
          </button>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
