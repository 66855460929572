import { Outlet } from "react-router-dom";
import { PageContent } from "../../components/Page";
import { SearchBar } from "./components/SearchBar";

export const SearchRoot: React.FC = () => {
  return (
    <>
      <SearchBar />
      <PageContent className="mt-4">
        <Outlet />
      </PageContent>
    </>
  );
};
