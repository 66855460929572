import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import { toast } from "sonner";
import { z } from "zod";
import { Reform } from "../../../components/Reform";
import { Spinner } from "../../../components/Spinner";
import { Bound } from "../../../components/forms/boundComponents";
import { Button, buttonVariants } from "../../../components/ui/button";
import { graphql } from "../../../gql";
import {
  ConnectionDriveStatus,
  FindConnectionQuery,
  IntegrationFragment,
  MinimalConnectionFragment,
  UpdateConnectionDriveInput,
} from "../../../gql/graphql";

const updateManyConnectionDrivesMutation = graphql(`
  mutation UpdateManyConnectionDrives($input: [UpdateConnectionDriveInput!]!) {
    updateManyConnectionDrives(input: $input) {
      id
      status
    }
  }
`);

const findConnectionQuery = graphql(`
  query FindConnection($id: ID!) {
    connection(id: $id) {
      ...MinimalConnection
      drives {
        id
        name
        url
        status
      }
    }
  }
`);

export const ConnectionRenderer: React.FC<{
  connection: MinimalConnectionFragment;
  integration: IntegrationFragment;
}> = ({ connection, integration }) => {
  const [updateManyConnectionDrives, { loading: updatingDrives }] = useMutation(
    updateManyConnectionDrivesMutation,
    {
      onCompleted: () => toast.success("Drives updated"),
      onError: () => toast.error("An error occurred"),
    }
  );

  const [loadConnection, { error, data }] = useLazyQuery(findConnectionQuery, {
    variables: { id: connection.id },
  });

  useEffect(() => {
    if (connection.isAuthorized) {
      loadConnection();
    }
  }, [connection, loadConnection]);

  if (!connection.isAuthorized) {
    return (
      <a
        href={`/oauth/authorize/${integration.provider}?scope=${integration.requiredScope}`}
        className={buttonVariants({ variant: "outline" })}
      >
        Authorize
      </a>
    );
  }

  const fullConnection = data?.connection;

  if (error) {
    return <Error />;
  } else if (!data) {
    return <Spinner />;
  } else if (!fullConnection) {
    return <Error />;
  }

  return (
    <div className="w-full">
      <ConnectionDriveList
        connection={fullConnection}
        onSubmit={(input) => {
          return updateManyConnectionDrives({ variables: { input } });
        }}
      />
      <div className="flex justify-end pt-4">
        <Button
          type="submit"
          size="sm"
          disabled={updatingDrives}
          form={`form-${connection.id}`}
        >
          Save
          {updatingDrives && <Spinner className="ml-1" />}
        </Button>
      </div>
    </div>
  );
};

const ConnectionDriveList: React.FC<{
  connection: NonNullable<FindConnectionQuery["connection"]>;
  onSubmit(inputs: UpdateConnectionDriveInput[]): void | Promise<unknown>;
}> = ({ connection, onSubmit }) => {
  if (connection.drives.length === 0) {
    return null;
  }

  const defaultValues =
    connection.drives.map(
      ({ id, status }) =>
        [
          id,
          status === ConnectionDriveStatus.Activating ||
            status === ConnectionDriveStatus.Active,
        ] as const
    ) ?? [];

  return (
    <Reform
      id={`form-${connection.id}`}
      schema={z.object({
        connection: z.record(z.boolean()),
      })}
      defaultValue={{
        connection: Object.fromEntries(defaultValues),
      }}
      onSubmit={async ({ connection }) => {
        const input = [...Object.entries(connection)].map(
          ([id, isActive]): UpdateConnectionDriveInput => ({
            id,
            isActive,
          })
        );
        await onSubmit(input);
      }}
    >
      <ul className="w-full">
        {connection.drives.map((drive) => (
          <li key={drive.id}>
            <label className="flex gap-2 items-center">
              <Bound.Switch name={`connection.${drive.id}`} />
              {drive.name}
            </label>
          </li>
        ))}
      </ul>
    </Reform>
  );
};

const Error = () => (
  <div className="flex flex-col w-full h-full justify-center items-center">
    <p className="text-red-800">We're sorry, an error occurred.</p>
    <FaCircleExclamation className="w-10 h-10 text-red-700 mt-4" />
  </div>
);
