type PromiseFunction<T, R> = (input: T) => Promise<R>;

export const batchPromiseOperations = async <T, R>(
  list: T[],
  fn: PromiseFunction<T, R>,
  batchSize: number
): Promise<R[]> => {
  const results: R[] = [];

  for (let i = 0; i < list.length; i += batchSize) {
    const batch = list.slice(i, i + batchSize);
    const batchResults = await Promise.all(batch.map(fn));
    results.push(...batchResults);
  }
  return results;
};

export function* batch<T>(arr: T[], batchSize: number): Generator<T[]> {
  for (let i = 0; i < arr.length; i += batchSize) {
    yield arr.slice(i, i + batchSize);
  }
}

export const waitMs = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
