import { Outlet } from "react-router-dom";
import {
  PageContent,
  PageHeader,
  PageHeaderLink,
  PageHeaderNav,
} from "../../components/Page";

export const OrganizationRoot: React.FC = () => (
  <>
    <PageHeader title="Organization Settings">
      <PageHeaderNav>
        <PageHeaderLink to="/organization/users">Users</PageHeaderLink>
      </PageHeaderNav>
    </PageHeader>
    <PageContent>
      <Outlet />
    </PageContent>
  </>
);
