import { useMutation } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import { FaSave } from "react-icons/fa";
import Markdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/Card";
import { Reform } from "../../../components/Reform";
import { BoundButton } from "../../../components/forms/BoundButton";
import { BoundLabel } from "../../../components/forms/BoundLabel";
import { Bound } from "../../../components/forms/boundComponents";
import { graphql } from "../../../gql";
import { spaceDetailsQuery } from "./SpaceDetailsRoot";

const formSchema = z.object({
  path: z.string(),
  content: z.string(),
});

const saveNoteMutation = graphql(`
  mutation SaveNote($input: SaveNoteInput!) {
    saveNote(input: $input) {
      id
    }
  }
`);
const ParamSchema = z.object({
  spaceId: z.string(),
});

export const CreateNotePage = () => {
  const { spaceId } = ParamSchema.parse(useParams());

  const navigate = useNavigate();
  const [saveNote] = useMutation(saveNoteMutation, {
    onError: () => toast.error("An error occurred"),
    onCompleted: () => navigate(`/spaces/${spaceId}`),
    refetchQueries: [spaceDetailsQuery],
  });
  return (
    <Reform
      schema={formSchema}
      onSubmit={async (data) => {
        await saveNote({
          variables: {
            input: {
              ...data,
              spaceId,
            },
          },
        });
      }}
    >
      <Card>
        <CardHeader>
          <CardTitle title="New Note" />
        </CardHeader>
        <CardBody>
          <BoundLabel name="path">
            <p>Name</p>
            <Bound.Input required name="path" />
          </BoundLabel>
          <BoundLabel name="content">
            <p>Note</p>
            <div className="flex gap-2">
              <Bound.Textarea required name="content" className="flex-1" />
              <NoteRender />
            </div>
          </BoundLabel>
        </CardBody>
        <CardFooter className="flex justify-end">
          <BoundButton icon={<FaSave />}>Save</BoundButton>
        </CardFooter>
      </Card>
    </Reform>
  );
};

const NoteRender: React.FC = () => {
  const { watch } = useFormContext<z.infer<typeof formSchema>>();
  const { content = "" } = watch();
  return (
    <Markdown className="prose flex-1 px-2 border-l border-zinc-600">
      {content}
    </Markdown>
  );
};
